const colors = {
  //TODO: add more colors as needed
  primary: "#FC4F06", // that orange color
  disabled: "#B0B0B0",
  success: "#17A221", // that green color
  error: "#AA0000",
  borders: "#CCCCCC",
  link: "#0095FF", //
  secondary: "#999999",
};

module.exports = colors;
