"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileDetailsWidget = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var send_otp_1 = require("../../../api/calls/user/send_otp");
var update_user_details_1 = require("../../../api/calls/user/update_user_details");
var main_1 = require("../../../reducers/slices/main");
var user_1 = require("../../../reducers/slices/user");
var ProfileDetailsWidget = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var _t = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _u = (0, react_1.useState)(false), isEditing = _u[0], setIsEditing = _u[1];
    var _v = (0, react_1.useState)(false), isSaving = _v[0], setIsSaving = _v[1];
    var _w = (0, react_1.useState)((_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : ""), name = _w[0], setName = _w[1];
    var _x = (0, react_1.useState)((_b = user === null || user === void 0 ? void 0 : user.surname) !== null && _b !== void 0 ? _b : ""), surname = _x[0], setSurname = _x[1];
    var _y = (0, react_1.useState)((_c = user === null || user === void 0 ? void 0 : user.email) !== null && _c !== void 0 ? _c : ""), email = _y[0], setEmail = _y[1];
    var _z = (0, react_1.useState)((_d = user === null || user === void 0 ? void 0 : user.cell_number) !== null && _d !== void 0 ? _d : ""), phoneNumber = _z[0], setPhoneNumber = _z[1];
    var _0 = (0, react_1.useState)((_f = (_e = user === null || user === void 0 ? void 0 : user.medical_aid) === null || _e === void 0 ? void 0 : _e.main_member) !== null && _f !== void 0 ? _f : false), isMainMember = _0[0], setIsMainMember = _0[1];
    var _1 = (0, react_1.useState)((_h = (_g = user.medical_aid) === null || _g === void 0 ? void 0 : _g.scheme) !== null && _h !== void 0 ? _h : ""), scheme = _1[0], setScheme = _1[1];
    var _2 = (0, react_1.useState)((_k = (_j = user === null || user === void 0 ? void 0 : user.medical_aid) === null || _j === void 0 ? void 0 : _j.member_no) !== null && _k !== void 0 ? _k : ""), memberNumber = _2[0], setMemberNumber = _2[1];
    var _3 = (0, react_1.useState)((_m = (_l = user === null || user === void 0 ? void 0 : user.medical_aid) === null || _l === void 0 ? void 0 : _l.main_member_name) !== null && _m !== void 0 ? _m : ""), mainName = _3[0], setMainName = _3[1];
    var _4 = (0, react_1.useState)((_p = (_o = user === null || user === void 0 ? void 0 : user.medical_aid) === null || _o === void 0 ? void 0 : _o.main_member_phone_no) !== null && _p !== void 0 ? _p : ""), mainPhoneNumber = _4[0], setMainPhoneNumber = _4[1];
    var _5 = (0, react_1.useState)((_r = (_q = user === null || user === void 0 ? void 0 : user.medical_aid) === null || _q === void 0 ? void 0 : _q.depandant_code) !== null && _r !== void 0 ? _r : ""), dependantCode = _5[0], setDependantCode = _5[1];
    var _6 = (0, react_1.useState)(""), nameMessage = _6[0], setNameMessage = _6[1];
    var _7 = (0, react_1.useState)(""), surnameMessage = _7[0], setSurnameMessage = _7[1];
    var _8 = (0, react_1.useState)(""), emailMessage = _8[0], setEmailMessage = _8[1];
    var _9 = (0, react_1.useState)(""), phoneNumberMessage = _9[0], setPhoneNumberMessage = _9[1];
    var _10 = (0, react_1.useState)(""), otpMessage = _10[0], setOTPMessage = _10[1];
    var _11 = (0, react_1.useState)(""), generatedOTP = _11[0], setGeneratedOTP = _11[1];
    var _12 = (0, react_1.useState)(""), enteredOTP = _12[0], setEnteredOTP = _12[1];
    var _13 = (0, react_1.useState)(0), otpCountdown = _13[0], setOTPCountdown = _13[1];
    var _14 = (0, react_1.useState)(null), timer = _14[0], setTimer = _14[1];
    var maxCOTPCountdown = parseInt((_s = process.env.OTP_COUNTDOWN) !== null && _s !== void 0 ? _s : "30");
    //
    var phoneNumberHasChnaged = (user === null || user === void 0 ? void 0 : user.cell_number) != phoneNumber;
    // const phoneNumberHasChnaged = true;
    //
    (0, react_1.useEffect)(function () {
        return function () {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, []);
    var validateDetails = function () {
        var messageCount = 0;
        setNameMessage("");
        setSurnameMessage("");
        setEmailMessage("");
        setPhoneNumberMessage("");
        setOTPMessage("");
        if (!name) {
            messageCount++;
            setEmailMessage("Name is required.");
        }
        if (!surname) {
            messageCount++;
            setSurnameMessage("Surname is required.");
        }
        if (!email) {
            messageCount++;
            setEmailMessage("Email is required.");
        }
        if (!phoneNumber) {
            messageCount++;
            setPhoneNumberMessage("Phone number is required.");
        }
        if (generatedOTP != enteredOTP) {
            messageCount++;
            setOTPMessage("OTP is incorrect.");
        }
        return messageCount ? false : true;
    };
    var onControlChanged = function (name, value) {
        var _a;
        dispatch((0, user_1.setUser)(__assign(__assign({}, user), { user: __assign(__assign({}, user), (_a = {}, _a[name] = value, _a)) })));
    };
    (0, react_1.useEffect)(function () {
        switch (isMainMember) {
            case true:
                setMainName("".concat(user.name, " ").concat(user.surname));
                setMainPhoneNumber("".concat(user.cell_number));
                break;
            case false:
                setMainName("");
                setMainPhoneNumber("");
                break;
        }
    }, [isMainMember]);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex flex-col gap-4 mt-8" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Details", size: "large", textColor: "text-black" })),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: isEditing ? pro_solid_svg_icons_1.faSave : pro_solid_svg_icons_1.faPen, backgroundColor: isEditing ? "bg-success" : "bg-primary", iconColor: "text-white", size: "xsmall", loading: isSaving, onClick: function () {
                        if (isEditing) {
                            if (validateDetails()) {
                                setIsSaving(true);
                                (0, update_user_details_1.update_user_details)({
                                    name: name,
                                    surname: surname,
                                    email: email,
                                    cell_number: phoneNumber,
                                    medical_aid: {
                                        scheme: scheme,
                                        member_no: memberNumber,
                                        main_member: isMainMember,
                                        main_member_name: mainName,
                                        main_member_phone_no: mainPhoneNumber,
                                        depandant_code: dependantCode,
                                    },
                                }).then(function (response) {
                                    setIsSaving(false);
                                    if (response.success) {
                                        dispatch((0, user_1.update_details_thunk)(response === null || response === void 0 ? void 0 : response.content));
                                        setIsEditing(false);
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                    }
                                });
                            }
                        }
                        else {
                            setIsEditing(true);
                        }
                    } }),
                isEditing && (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faClose, backgroundColor: "bg-primary", iconColor: "text-white", size: "xsmall", onClick: function () {
                        setIsEditing(false);
                    } })))),
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: name, disabled: !isEditing, size: "small", errorText: nameMessage, textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setName(v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: surname, disabled: !isEditing, size: "small", errorText: surnameMessage, textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setSurname(v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email address", value: email, disabled: !isEditing, size: "small", errorText: emailMessage, textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setEmail(v); } }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                    react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cellphone number", type: "number", value: phoneNumber, disabled: !isEditing, size: "small", errorText: phoneNumberMessage, textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setPhoneNumber(v); } }),
                        generatedOTP && phoneNumberHasChnaged && (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "OTP", type: "number", value: enteredOTP, disabled: !isEditing, size: "small", errorText: otpMessage, textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setEnteredOTP(v); } }))),
                    phoneNumberHasChnaged && (react_1.default.createElement("div", null, otpCountdown > 0 ? (react_1.default.createElement("div", { className: "w-10" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: otpCountdown.toString(), size: "small", backgroundColor: "bg-success" }))) : (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faSend, backgroundColor: "bg-success", iconColor: "text-white", size: "small", onClick: function () {
                            var otp = doshx_controls_web_1.Utilities.generateOTP();
                            (0, send_otp_1.send_otp)({
                                otp: otp,
                                cell_number: phoneNumber,
                                // name: name,
                            }).then(function (response) {
                                if (response.success) {
                                    setGeneratedOTP(otp);
                                    setOTPCountdown(maxCOTPCountdown);
                                    var timeLeft_1 = maxCOTPCountdown;
                                    if (timer) {
                                        clearTimeout(timer);
                                    }
                                    setTimer(setInterval(function () {
                                        timeLeft_1 = timeLeft_1 - 1;
                                        setOTPCountdown(timeLeft_1);
                                        if (timer && timeLeft_1 === 0) {
                                            clearTimeout(timer);
                                        }
                                    }, 1000));
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: response.message }));
                                }
                            });
                        } })))))),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Medical aid name", value: scheme, disabled: !isEditing, size: "small", 
                // errorText={}
                textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setScheme(v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Medical aid member number", value: memberNumber, disabled: !isEditing, size: "small", 
                // errorText={}
                textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setMemberNumber(v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Dependant code", value: dependantCode, disabled: !isEditing, size: "small", 
                // errorText={}
                textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", onChange: function (v) { return setDependantCode(v); } }),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Are you the main member for this medical aid?", uncheckedColor: "text-primary", value: isMainMember, disabled: !isEditing, onChange: function (v) {
                    setIsMainMember(v);
                } }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 " },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name of main member for medical aid", value: mainName, disabled: !isEditing, size: "small", 
                    // errorText={}
                    textColor: isEditing && !isMainMember ? "text-black" : "text-disabled", labelColor: isEditing && !isMainMember ? "text-black" : "text-disabled", borderColor: isEditing && !isMainMember ? "border-borders" : "border-disabled", onChange: function (v) { return setMainName(v); } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Phone number of main member for medical aid", value: mainPhoneNumber, disabled: !isEditing, size: "small", 
                    // errorText={}
                    textColor: isEditing && !isMainMember ? "text-black" : "text-disabled", labelColor: isEditing && !isMainMember ? "text-black" : "text-disabled", borderColor: isEditing && !isMainMember ? "border-borders" : "border-disabled", onChange: function (v) { return setMainPhoneNumber(v); } })))));
};
exports.ProfileDetailsWidget = ProfileDetailsWidget;
