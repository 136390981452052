"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalAidComponent = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var update_user_details_1 = require("../../../../api/calls/user/update_user_details");
var user_1 = require("../../../../reducers/slices/user");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../reducers/slices/main");
var control_colors_1 = require("../../../../constants/control_colors");
var MedicalAidComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _l = (0, react_1.useState)("yes"), hasMedicalAid = _l[0], setHasMedicalAid = _l[1];
    var _m = (0, react_1.useState)(false), validated = _m[0], setValidated = _m[1];
    var _o = (0, react_1.useState)(true), isOpen = _o[0], setIsOpen = _o[1];
    var _p = (0, react_1.useState)("yes"), isMainMember = _p[0], setIsMainMember = _p[1];
    var _q = (0, react_1.useState)(false), isLoading = _q[0], setIsLoading = _q[1];
    var _r = (0, react_1.useState)((_b = (_a = user.medical_aid) === null || _a === void 0 ? void 0 : _a.scheme) !== null && _b !== void 0 ? _b : ""), scheme = _r[0], setScheme = _r[1];
    var _s = (0, react_1.useState)((_d = (_c = user === null || user === void 0 ? void 0 : user.medical_aid) === null || _c === void 0 ? void 0 : _c.member_no) !== null && _d !== void 0 ? _d : ""), memberNumber = _s[0], setMemberNumber = _s[1];
    var _t = (0, react_1.useState)((_f = (_e = user === null || user === void 0 ? void 0 : user.medical_aid) === null || _e === void 0 ? void 0 : _e.main_member_name) !== null && _f !== void 0 ? _f : ""), mainName = _t[0], setMainName = _t[1];
    var _u = (0, react_1.useState)((_h = (_g = user === null || user === void 0 ? void 0 : user.medical_aid) === null || _g === void 0 ? void 0 : _g.main_member_phone_no) !== null && _h !== void 0 ? _h : ""), mainPhoneNumber = _u[0], setMainPhoneNumber = _u[1];
    var _v = (0, react_1.useState)((_k = (_j = user === null || user === void 0 ? void 0 : user.medical_aid) === null || _j === void 0 ? void 0 : _j.depandant_code) !== null && _k !== void 0 ? _k : ""), dependantCode = _v[0], setDependantCode = _v[1];
    (0, react_1.useEffect)(function () { }, []);
    var updateMedicalAid = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, (0, update_user_details_1.update_user_details)({
                            name: "".concat(user.name),
                            surname: "".concat(user.surname),
                            email: "".concat(user.email),
                            cell_number: user.cell_number,
                            medical_aid: {
                                scheme: scheme,
                                member_no: memberNumber,
                                main_member: isMainMember === "yes" ? true : false,
                                main_member_name: mainName,
                                main_member_phone_no: mainPhoneNumber,
                                depandant_code: dependantCode,
                            },
                        }).then(function (response) {
                            if (response.success) {
                                dispatch((0, user_1.update_details_thunk)(response === null || response === void 0 ? void 0 : response.content));
                                setIsOpen(false);
                                setIsLoading(false);
                                window.scrollTo({
                                    top: 1300,
                                    behavior: "smooth",
                                });
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Failed to update medical aid data", messages: [response.message] }));
                                setIsLoading(false);
                            }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        setValidated(false);
        switch (hasMedicalAid) {
            case "yes":
                if (scheme && memberNumber && dependantCode) {
                    switch (isMainMember) {
                        case "yes":
                            setValidated(true);
                            break;
                        case "no":
                            if (mainName && mainPhoneNumber) {
                                setValidated(true);
                            }
                            else {
                                setValidated(false);
                            }
                            break;
                    }
                }
                break;
            case "no":
                setValidated(true);
                break;
        }
    }, [isMainMember, hasMedicalAid, scheme, memberNumber, dependantCode, mainName, mainPhoneNumber]);
    (0, react_1.useEffect)(function () {
        switch (isMainMember) {
            case "yes":
                setMainName("".concat(user.name, " ").concat(user.surname));
                setMainPhoneNumber("".concat(user.cell_number));
                break;
            case "no":
                setMainName(mainName);
                setMainPhoneNumber(mainPhoneNumber);
                break;
        }
    }, [isMainMember, user]);
    return (react_1.default.createElement("div", { className: "m-4 p-4" },
        react_1.default.createElement(doshx_controls_web_1.ModalControl, { title: "Profile Details", open: isOpen, onClose: function () {
                setIsOpen(false);
            } }, isLoading ? (react_1.default.createElement("div", { className: "flex flex-col gap-4 w-full md:w-[600px] " },
            react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "medium", progressColor: "text-primary" }))) : (react_1.default.createElement("div", { className: "flex flex-col gap-4 w-full md:w-[600px] " },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "We take your privacy seriously and your information is always secure with us.", textColor: "text-black" }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have Medical Aid?", colors: control_colors_1.radio_colors, selectedValue: hasMedicalAid, options: [
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" },
                    ], onChange: function (value) {
                        setHasMedicalAid(value);
                    } }),
                hasMedicalAid === "yes" ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Select your Medical Aid Scheme", value: scheme, required: true, onChange: function (v) { return setScheme(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Medical Aid Member Number", value: memberNumber, required: true, onChange: function (v) { return setMemberNumber(v); } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Dependant Code", value: dependantCode, required: true, onChange: function (v) {
                            setDependantCode(v);
                        } }))) : null),
            hasMedicalAid === "yes" ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Main Member", colors: control_colors_1.radio_colors, selectedValue: isMainMember, options: [
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" },
                    ], onChange: function (value) {
                        setIsMainMember(value);
                    } }),
                react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "If \u201CNo\u201D Please fill required details below.", size: "small", textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Main Member Full Name", value: mainName, required: true, onChange: function (v) {
                            setMainName(v);
                        }, disabled: isMainMember === "yes" ? true : false, textColor: isMainMember === "yes" ? "text-disabled" : "text-black", labelColor: isMainMember === "yes" ? "text-disabled" : "text-black", borderColor: isMainMember === "yes" ? "border-disabled" : "border-borders" }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Main Member Contact Number", value: mainPhoneNumber, required: true, onChange: function (v) { return setMainPhoneNumber(v); }, disabled: isMainMember === "yes" ? true : false, textColor: isMainMember === "yes" ? "text-disabled" : "text-black", labelColor: isMainMember === "yes" ? "text-disabled" : "text-black", borderColor: isMainMember === "yes" ? "border-disabled" : "border-borders" })))) : null,
            react_1.default.createElement("div", { className: "flex flex-col items-center" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Update Medical Aid Data", backgroundColor: "bg-primary", onClick: function () {
                        updateMedicalAid();
                    }, enabled: validated ? true : false })))))));
};
exports.MedicalAidComponent = MedicalAidComponent;
