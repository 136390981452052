"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSelectedProduct = exports.setSelectedCategory = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    selection: {
        selectedCategory: undefined,
        selectedProduct: undefined,
    },
};
var slice = (0, toolkit_1.createSlice)({
    name: "selection",
    initialState: initialState,
    reducers: {
        setSelectedCategory: function (state, action) {
            state.selection.selectedCategory = action.payload;
        },
        setSelectedProduct: function (state, action) {
            state.selection.selectedProduct = action.payload;
        },
    },
});
exports.setSelectedCategory = (_a = slice.actions, _a.setSelectedCategory), exports.setSelectedProduct = _a.setSelectedProduct;
exports.default = slice.reducer;
