"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.add_product_thunk = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var basket_1 = require("../basket");
var create_basket_1 = require("../../../../api/calls/basket/create_basket");
var add_to_basket_1 = require("../../../../api/calls/basket/add_to_basket");
var main_1 = require("../../main");
var get_basket_thunk_1 = require("./get_basket_thunk");
var google_tags_1 = require("../../../../constants/google_tags");
exports.add_product_thunk = (0, toolkit_1.createAsyncThunk)("basket/thunks/add_product_thunk", function (product, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, basket, user, basketID, response;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    state = getState();
                    basket = state.basketState.basket;
                    user = state.userState.user;
                    if (!user._id) return [3 /*break*/, 5];
                    dispatch((0, basket_1.setIsAddingToBasket)(true));
                    //Making Sure the product is available
                    if (product.status != "AVAILABLE") {
                        dispatch((0, main_1.setMessageModal)({
                            title: "".concat(product === null || product === void 0 ? void 0 : product.status.replace(/-/g, " ")),
                            messages: ["".concat(product.title, " is currently ").concat(product === null || product === void 0 ? void 0 : product.status.replace(/-/g, " "), "."), " Please check again later."],
                        }));
                        dispatch((0, basket_1.setIsAddingToBasket)(false));
                        return [2 /*return*/];
                    }
                    (0, google_tags_1.trackAddToCart)({
                        products: [
                            {
                                title: product.title,
                                _id: product._id,
                                price: (_b = product.price) !== null && _b !== void 0 ? _b : product.amount,
                                quantity: product.quantity,
                                sku: product.sku,
                            },
                        ],
                        _id: product._id,
                        price: (_c = product.price) !== null && _c !== void 0 ? _c : product.amount,
                    });
                    dispatch((0, main_1.setMessageModal)({
                        title: "Please Wait...",
                        messages: ["Please wait while we add your product to the basket."],
                    }));
                    basketID = basket === null || basket === void 0 ? void 0 : basket._id;
                    if (!!basket) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, create_basket_1.create_basket)({
                        //  user_id: user._id,
                        })];
                case 1:
                    response = _d.sent();
                    if (response.success) {
                        basketID = response.content._id;
                    }
                    _d.label = 2;
                case 2:
                    if (!basketID) return [3 /*break*/, 4];
                    //Google Events
                    return [4 /*yield*/, (0, add_to_basket_1.add_to_basket)({
                            _id: basketID,
                            product: {
                                _id: product === null || product === void 0 ? void 0 : product._id,
                                quantity: product.quantity ? product.quantity.toString() : "1",
                            },
                        }).then(function (response) {
                            if (response.success) {
                                dispatch((0, get_basket_thunk_1.get_basket_thunk)());
                                dispatch((0, main_1.setMessageModal)({ title: "Cart!", messages: ["Product Sucessfully Added to Cart."] }));
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            }
                        })];
                case 3:
                    //Google Events
                    _d.sent();
                    _d.label = 4;
                case 4:
                    dispatch((0, basket_1.setIsAddingToBasket)(false));
                    return [3 /*break*/, 6];
                case 5:
                    dispatch((0, main_1.setDrawerPage)("login"));
                    dispatch((0, main_1.setDrawerOpen)(true));
                    _d.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    });
});
