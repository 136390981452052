"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlogComponent = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var news_1 = require("../../../../reducers/slices/news");
var blogs_1 = require("../../../../api/calls/strapi/blogs");
var react_redux_1 = require("react-redux");
var BlogComponent = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var carouselRef = (0, react_1.useRef)(null);
    var _a = (0, react_1.useState)([]), blogCarousel = _a[0], setBlogCarouselItems = _a[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    var news = (0, react_redux_1.useSelector)(function (state) { return state.newsState; }).news;
    (0, react_1.useEffect)(function () {
        (0, blogs_1.get_blogs)().then(function (data) {
            if (data && data.length > 0) {
                dispatch((0, news_1.setNewsList)(data));
            }
            else {
            }
        });
    }, []);
    (0, react_1.useEffect)(function () {
        var sample = [];
        var _loop_1 = function (blog) {
            sample.push({
                content: (react_1.default.createElement("div", { className: "flex flex-col justify-end items-end " },
                    react_1.default.createElement("img", { className: "w-full h-full object-cover absolute top-0 left-0 -z-20", src: "".concat(process.env.STRAPI_TLC_URL).concat(blog.image.url) }),
                    react_1.default.createElement("div", { className: "bg-white p-2 w-full" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: blog === null || blog === void 0 ? void 0 : blog.title, textColor: "text-black", size: "small", center: true, bold: true }),
                        react_1.default.createElement("div", { className: "flex flex-row justify-between p-2" },
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Read More", size: "small", textColor: "text-blue-600", decorationColor: "decoration-black", onClick: function () {
                                    navigate("/news/".concat(blog.id));
                                } }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatDateTime(blog.published_at, "DD/MM/YYYY"), textColor: "text-primary", size: "small", center: true }))))),
                onClick: function () {
                    navigate("/news/".concat(blog.id));
                },
            });
        };
        for (var _i = 0, _a = news.list; _i < _a.length; _i++) {
            var blog = _a[_i];
            _loop_1(blog);
        }
        setBlogCarouselItems(sample);
    }, [news]);
    return (react_1.default.createElement("div", { className: "w-full" },
        react_1.default.createElement("div", { className: "bg-secondary p-2 flex" },
            react_1.default.createElement("div", { className: "flex gap-2 items-center flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Blog", textColor: "text-white", size: "large", bold: true })),
            react_1.default.createElement("div", { className: "flex items-center justify-between" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "View All", size: "small", backgroundColor: "bg-transparent", onClick: function () {
                        navigate("/news");
                    } }))),
        react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-12 gap-4 bg-white  rounded-xl p-4" },
            react_1.default.createElement("div", { className: "flex flex-row items-center pb-4" },
                react_1.default.createElement("div", { className: "flex flex-col cursor-pointer", onClick: function () {
                        var _a;
                        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.prev();
                    } },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faChevronCircleLeft, className: "text-primary", size: "2x" }))),
            react_1.default.createElement("div", { className: "flex  justify-center items-center md:col-span-10 gap-4 " },
                react_1.default.createElement(doshx_controls_web_1.ContentCarouselControl, { shortSwipes: true, ref: carouselRef, heightClasses: "h-96 md:h-80", centerSlides: false, 
                    // viewport={4}
                    breakpoints: {
                        sm: {
                            viewport: 1,
                        },
                        md: {
                            viewport: 4,
                        },
                    }, slideTimeout: 1700, colors: {
                        bulletColor: "bg-white",
                    }, items: blogCarousel })),
            react_1.default.createElement("div", { className: "flex flex-row items-center pb-4" },
                react_1.default.createElement("div", { className: "flex flex-col cursor-pointer", onClick: function () {
                        var _a;
                        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next();
                    } },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faChevronCircleRight, className: "text-primary", size: "2x" }))))));
};
exports.BlogComponent = BlogComponent;
