"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStrippedPath = exports.getRootPath = void 0;
function getRootPath(location) {
    var _a;
    return "/".concat((_a = location.pathname.split("/")[1]) !== null && _a !== void 0 ? _a : "");
}
exports.getRootPath = getRootPath;
function getStrippedPath(location) {
    var qwe = location.pathname.split("/").filter(function (x) { return x !== ""; });
    qwe.pop();
    return "/".concat(qwe.join("/"));
}
exports.getStrippedPath = getStrippedPath;
