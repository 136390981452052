"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotFoundPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var NotFoundPage = function (props) {
    var _a = props;
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () {
        //
    }, []);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                    react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Page Not Found", textColor: "text-black", size: "medium" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "The page you were looking for does not exist.", textColor: "text-black", size: "6xlarge", bold: true }),
                        react_1.default.createElement("div", { className: "flex gap-2" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Email ", textColor: "text-black", size: "small" }),
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: " care@thelocalchoice.net", size: "small", textColor: "text-primary", onClick: function () {
                                    window.open("mailto:care@thelocalchoice.net?subject=Website Contact Form", "_blank");
                                } }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: " or call ", textColor: "text-black", size: "small" }),
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: " 013 665 1698", size: "small", textColor: "text-primary", onClick: function () {
                                    window.open("tel:+27136651698", "_blank");
                                } })),
                        react_1.default.createElement("div", { className: "flex" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { backgroundColor: "bg-success", label: "Go Home", onClick: function () {
                                    navigate("/home");
                                } }))),
                    react_1.default.createElement("div", { className: "flex justify-center items-center " },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "404", bold: true, center: true, textColor: "text-primary", size: "9xlarge", uppercase: true })))))));
};
exports.NotFoundPage = NotFoundPage;
