"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNewsList = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
// const userActions = createExtraActions();
// const extraReducers = createExtraReducers();
var initialState = {
    news: {
        list: [],
    },
};
var slice = (0, toolkit_1.createSlice)({
    name: "news",
    initialState: initialState,
    reducers: {
        setNewsList: function (state, action) {
            state.news.list = action.payload;
        },
    },
});
exports.setNewsList = slice.actions.setNewsList;
exports.default = slice.reducer;
