"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectBankCardStep = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var checkout_1 = require("../../../../reducers/slices/checkout");
var SelectBankCardStep = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.checkoutState; }), currentCheckoutStep = _a.currentCheckoutStep, checkoutSteps = _a.checkoutSteps, selectedBankCard = _a.selectedBankCard, bankCards = _a.bankCards;
    var basket = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }).basket;
    (0, react_1.useEffect)(function () { }, []);
    var onNext = function () {
        var nextStep = currentCheckoutStep + 1;
        if (nextStep < checkoutSteps.length) {
            dispatch((0, checkout_1.setCurrentCheckoutStep)(nextStep));
        }
    };
    var onPrevious = function () {
        var previousStep = currentCheckoutStep - 1;
        if (currentCheckoutStep > 0) {
            dispatch((0, checkout_1.setCurrentCheckoutStep)(previousStep));
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", null, bankCards && bankCards.length > 0 ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" }, bankCards.map(function (bankCard, index) {
            return (react_1.default.createElement("div", { key: index, className: "p-4 border border-gray-400 rounded-md cursor-pointer", onClick: function () {
                    if (bankCard.id == (selectedBankCard === null || selectedBankCard === void 0 ? void 0 : selectedBankCard.id)) {
                        dispatch((0, checkout_1.setSelectedBankCard)(null));
                    }
                    else {
                        dispatch((0, checkout_1.setSelectedBankCard)(bankCard));
                    }
                } },
                react_1.default.createElement("div", { className: "flex flex-row items-center" },
                    react_1.default.createElement("div", { className: "flex flex-col flex-1" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: bankCard.card_type, className: "cursor-pointer" //
                            , textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: bankCard.card_number, className: "cursor-pointer" //
                            , textColor: "text-black" })),
                    bankCard.id == (selectedBankCard === null || selectedBankCard === void 0 ? void 0 : selectedBankCard.id) && (react_1.default.createElement("div", { className: "rounded-lg bg-primary px-2 py-1 flex flex-row items-center" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "SELECTED", className: "cursor-pointer" //
                            , textColor: "text-white", size: "xsmall" }))))));
        }))) : (react_1.default.createElement("div", null,
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You don't have any saved bank cards. You can save a card on the next step." })))),
        react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Next", backgroundColor: "bg-primary", size: "small", loading: false, onClick: function () {
                    onNext();
                } }),
            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Back", size: "small", onClick: function () {
                    onPrevious();
                } }))));
};
exports.SelectBankCardStep = SelectBankCardStep;
