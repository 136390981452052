"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionSwapperComponent = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var SectionSwapperComponent = function (props) {
    var imageSide = props.imageSide, image = props.image, smallTitle = props.smallTitle, bigTitle = props.bigTitle, description = props.description, content = props.content, buttonText = props.buttonText, onButtonClick = props.onButtonClick;
    (0, react_1.useEffect)(function () {
        //
    }, []);
    var imageComponent = function () {
        return (react_1.default.createElement("div", { className: "aspect-square" },
            react_1.default.createElement("img", { src: image, className: "w-full rounded-lg shadow-lg object-cover" })));
    };
    return (react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-8 items-center" },
        imageSide == "left" && imageComponent(),
        react_1.default.createElement("div", { className: "flex flex-col gap-4 justify-center" },
            react_1.default.createElement("div", { className: "flex flex-col" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: smallTitle.toUpperCase(), size: "medium", textColor: "text-success" }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: bigTitle, size: "3xlarge", textColor: "text-black", bold: true })),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: description, size: "medium", textColor: "text-black" }),
            react_1.default.createElement("div", { className: "border-l-8 pl-4 rounded-l-lg border-primary flex flex-col gap-4" }, content.map(function (item, index) {
                return (react_1.default.createElement("div", { key: index, className: "flex flex-row items-start gap-4" },
                    typeof item === "string" && (react_1.default.createElement("div", { className: "text-lg text-success" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCheckCircle }))),
                    react_1.default.createElement("div", null, typeof item === "string" ? (item) : (react_1.default.createElement("div", { className: "" },
                        item.title && (react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: item.title, textColor: "text-black", size: "medium", bold: true }))),
                        item.description && (react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: item.description, textColor: "text-black", size: "medium" }))),
                        item.list && item.list.length > 0 && (react_1.default.createElement("div", { className: "flex flex-col gap-4 pt-4" }, item.list.map(function (cont, index) {
                            return (react_1.default.createElement("div", { key: index, className: "flex flex-row items-start gap-4" },
                                react_1.default.createElement("div", { className: "text-lg text-success" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCheckCircle })),
                                react_1.default.createElement("div", null, cont)));
                        }))))))));
            })),
            buttonText && (react_1.default.createElement("div", { className: "flex flex-row justify-start" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: buttonText, backgroundColor: "bg-primary", onClick: onButtonClick })))),
        imageSide == "right" && imageComponent()));
};
exports.SectionSwapperComponent = SectionSwapperComponent;
