"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileAddressWidget = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var address_list_1 = require("../../../api/calls/user/address_list");
var add_user_address_1 = require("../../../api/calls/user/add_user_address");
var delete_address_1 = require("../../../api/calls/user/delete_address");
var main_1 = require("../../../reducers/slices/main");
var ProfileAddressWidget = function (props) {
    var _a = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_1.useState)(false), isEditing = _b[0], setIsEditing = _b[1];
    var _c = (0, react_1.useState)(), selectedAddress = _c[0], setSelectedAddress = _c[1];
    var _d = (0, react_1.useState)([]), addressList = _d[0], setAddressList = _d[1];
    var _e = (0, react_1.useState)(""), newAddressName = _e[0], setNewAddressName = _e[1];
    var _f = (0, react_1.useState)(""), addressLabelErrorText = _f[0], setAddressLabelErrorText = _f[1];
    var _g = (0, react_1.useState)(-1), deletingIndex = _g[0], setDeletingIndex = _g[1];
    var _h = (0, react_1.useState)(false), isSaving = _h[0], setIsSaving = _h[1];
    var _j = (0, react_1.useState)(false), isDeleting = _j[0], setIsDeleting = _j[1];
    (0, react_1.useEffect)(function () {
        getAddresses();
    }, []);
    var getAddresses = function () {
        (0, address_list_1.address_list)().then(function (response) {
            if (response.success) {
                setAddressList(response.content.addresses);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
            }
        });
    };
    var validateAddress = function () {
        var messages = [];
        var errorCount = 0;
        setAddressLabelErrorText("");
        if (!selectedAddress) {
            messages.push("Please select an address. When you searched, please click on a prediction to select it.");
        }
        if (!newAddressName) {
            errorCount++;
            setAddressLabelErrorText("Please enter a label for your new address.");
        }
        if (messages.length > 0 || errorCount > 0) {
            if (messages.length > 0) {
                dispatch((0, main_1.setMessageModal)({ title: "Address selection needed!", messages: messages }));
            }
            return false;
        }
        else {
            return true;
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 p-4 border border-borders rounded-lg" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Addresses", size: "large", textColor: "text-black" })),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: isEditing ? pro_solid_svg_icons_1.faSave : pro_solid_svg_icons_1.faAdd, backgroundColor: isEditing ? "bg-success" : "bg-primary", iconColor: "text-white", size: "xsmall", loading: isSaving, onClick: function () {
                        var _a, _b, _c;
                        if (isEditing) {
                            if (validateAddress()) {
                                setIsSaving(true);
                                (0, add_user_address_1.add_user_address)({
                                    label: newAddressName,
                                    full_address: (_a = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address) !== null && _a !== void 0 ? _a : "",
                                    latitude: (_b = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.latitude) !== null && _b !== void 0 ? _b : "",
                                    longitude: (_c = selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.longitude) !== null && _c !== void 0 ? _c : "",
                                    //TO DO
                                    // line_one: selectedAddress?.line_one ?? "",
                                    // line_two: selectedAddress?.line_two ?? "",
                                    // town: selectedAddress?.town ?? "",
                                    // city: selectedAddress?.city ?? "",
                                    // province: selectedAddress?.province ?? "",
                                    // country: selectedAddress?.country ?? "",
                                    // postal_code: selectedAddress?.postal_code ?? "",
                                }).then(function (response) {
                                    setIsSaving(false);
                                    if (response.success) {
                                        setIsEditing(false);
                                        getAddresses();
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                    }
                                });
                            }
                        }
                        else {
                            setIsEditing(true);
                        }
                    } }),
                isEditing && (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faClose, backgroundColor: "bg-primary", iconColor: "text-white", size: "xsmall", onClick: function () {
                        setIsEditing(false);
                    } })))),
        isEditing ? (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.AddressSearchControl, { letsTradeAPIUrl: process.env.LETS_TRADE_CLIENT_URL, 
                // value={address}
                required: true, placeholder: "Search & select address...", 
                // label="Search Address"
                size: "small", onSelectionChange: function (value) {
                    var _a, _b, _c, _d, _e, _f, _g;
                    setSelectedAddress({
                        full_address: value.fullAddress,
                        longitude: value.coordinates.lng,
                        latitude: value.coordinates.lat,
                        line_one: (_a = value.street_number) !== null && _a !== void 0 ? _a : "",
                        line_two: (_b = value.street_name) !== null && _b !== void 0 ? _b : "",
                        town: (_c = value.suburb) !== null && _c !== void 0 ? _c : "",
                        city: (_d = value.city) !== null && _d !== void 0 ? _d : "",
                        province: (_e = value.province) !== null && _e !== void 0 ? _e : "",
                        country: (_f = value.country) !== null && _f !== void 0 ? _f : "",
                        postal_code: (_g = value.postal_code) !== null && _g !== void 0 ? _g : "",
                    });
                } }),
            react_1.default.createElement("div", null, (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address) && (react_1.default.createElement("div", { className: "flex flex-col gap-1" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", size: "small", borderColor: "border-borders", onChange: function (v) { return setNewAddressName(v); }, errorText: addressLabelErrorText }),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedAddress.full_address, textColor: "text-black", size: "xsmall" }))))))) : (react_1.default.createElement("div", { className: "" }, addressList && addressList.length > 0 ? (addressList.map(function (address, index) {
            return (react_1.default.createElement("div", { key: index, className: "".concat(index == 0 ? "" : "border-t border-borders", " mb-2 pt-2 flex flex-row gap-4 items-center") },
                react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address.label, bold: true, textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: address.full_address })),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrash, backgroundColor: "bg-error", iconColor: "text-white", size: "xsmall", loading: index == deletingIndex && isDeleting, onClick: function () {
                            setIsDeleting(true);
                            setDeletingIndex(index);
                            (0, delete_address_1.delete_address)({
                                _id: address._id,
                            }).then(function (response) {
                                setIsDeleting(false);
                                setDeletingIndex(-1);
                                if (response.success) {
                                    getAddresses();
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Something went wrong!",
                                        messages: [response.message],
                                    }));
                                }
                            });
                        } }))));
        })) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You haven't added any addresses." }))))));
};
exports.ProfileAddressWidget = ProfileAddressWidget;
