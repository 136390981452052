"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../reducers/slices/main");
var user_1 = require("../../reducers/slices/user");
var react_router_dom_1 = require("react-router-dom");
var send_otp_1 = require("../../api/calls/user/send_otp");
var SignUpPage = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_1.useState)(""), nameMessage = _b[0], setNameMessage = _b[1];
    var _c = (0, react_1.useState)(""), surnameMessage = _c[0], setSurnameMessage = _c[1];
    var _d = (0, react_1.useState)(""), emailMessage = _d[0], setEmailMessage = _d[1];
    var _e = (0, react_1.useState)(""), phoneMessage = _e[0], setPhoneMessage = _e[1];
    var _f = (0, react_1.useState)(""), passwordMessage = _f[0], setPasswordMessage = _f[1];
    var _g = (0, react_1.useState)(""), confirmPasswordMessage = _g[0], setConfirmPasswordMessage = _g[1];
    var _h = (0, react_1.useState)(""), agreesMessage = _h[0], setAgreesMessage = _h[1];
    var _j = (0, react_1.useState)(false), isEditingOTP = _j[0], setIsEditingOTP = _j[1];
    var _k = (0, react_1.useState)(""), otpMessage = _k[0], setOTPMessage = _k[1];
    var _l = (0, react_1.useState)(""), generatedOTP = _l[0], setGeneratedOTP = _l[1];
    var _m = (0, react_1.useState)(""), enteredOTP = _m[0], setEnteredOTP = _m[1];
    var _o = (0, react_1.useState)(0), otpCountdown = _o[0], setOTPCountdown = _o[1];
    var _p = (0, react_1.useState)(null), timer = _p[0], setTimer = _p[1];
    var maxCOTPCountdown = parseInt((_a = process.env.OTP_COUNTDOWN) !== null && _a !== void 0 ? _a : "30");
    (0, react_1.useEffect)(function () {
        //
    }, []);
    var onControlChanged = function (name, value) {
        var _a;
        dispatch((0, user_1.setUser)(__assign(__assign({}, user), (_a = {}, _a[name] = value, _a))));
    };
    var validate = function () {
        var messageCount = 0;
        setNameMessage("");
        setSurnameMessage("");
        setEmailMessage("");
        setPhoneMessage("");
        setPasswordMessage("");
        setConfirmPasswordMessage("");
        setAgreesMessage("");
        if (!user.name) {
            messageCount++;
            setNameMessage("Name is required.");
        }
        if (!user.surname) {
            messageCount++;
            setSurnameMessage("Surname is required.");
        }
        if (!user.email) {
            messageCount++;
            setEmailMessage("Email is required.");
        }
        if (!user.cell_number) {
            messageCount++;
            setPhoneMessage("Phone Number is required.");
        }
        if (!user.password) {
            messageCount++;
            setPasswordMessage("Password is required.");
        }
        if (!user.confirm_password) {
            messageCount++;
            setConfirmPasswordMessage("Confirm Password is required.");
        }
        else {
            if (user.password != user.confirm_password) {
                messageCount++;
                setConfirmPasswordMessage("Passwords don't match.");
            }
        }
        if (!user.agrees_terms_and_conditions) {
            messageCount++;
            setAgreesMessage("Must agree to our terms and conditions.");
        }
        return messageCount ? false : true;
    };
    var validateOTP = function () {
        if (generatedOTP != enteredOTP) {
            setOTPMessage("OTP is incorrect.");
        }
        else {
            dispatch((0, user_1.sign_up_thunk)());
        }
    };
    var generate_otp = function () {
        //Send OTP if validated
        var otp = doshx_controls_web_1.Utilities.generateOTP();
        (0, send_otp_1.send_otp)({
            otp: otp,
            cell_number: user.cell_number,
            // name: user.name,
        }).then(function (response) {
            if (response.success) {
                setIsEditingOTP(true);
                setGeneratedOTP(otp);
                setOTPCountdown(maxCOTPCountdown);
                var timeLeft_1 = maxCOTPCountdown;
                if (timer) {
                    clearTimeout(timer);
                }
                setTimer(setInterval(function () {
                    timeLeft_1 = timeLeft_1 - 1;
                    setOTPCountdown(timeLeft_1);
                    if (timer && timeLeft_1 === 0) {
                        clearTimeout(timer);
                    }
                }, 1000));
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: response.message }));
            }
        });
    };
    return (react_1.default.createElement("div", { className: "flex flex-col w-96 h-full justify-center overflow-y-auto" }, isEditingOTP ? (react_1.default.createElement("div", { className: "flex flex-col gap-y-4 overflow-auto" },
        react_1.default.createElement("div", { className: "text-3xl font-bold" }, "Confirm OTP"),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "OTP", type: "number", value: enteredOTP, 
            // disabled={!isEditingOTP}
            size: "small", errorText: otpMessage, textColor: "text-black", labelColor: "text-black", borderColor: "border-borders", onChange: function (v) { return setEnteredOTP(v); } }),
        otpCountdown > 0 ? (react_1.default.createElement("div", { className: "w-full flex justify-center" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: " Request another OTP in ".concat(otpCountdown.toString(), " seconds"), size: "small", textColor: "text-black" }))) : (react_1.default.createElement("div", { className: "w-full flex justify-center" },
            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: " Request another OTP", size: "small", textColor: "text-primary", onClick: function () { return generate_otp(); } }))),
        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Submit OTP", backgroundColor: "bg-primary", loading: user.is_loading, onClick: function () {
                validateOTP();
            } }))) : (react_1.default.createElement("div", { className: "flex flex-col gap-y-4 overflow-auto" },
        react_1.default.createElement("div", { className: "text-3xl font-bold" }, "Create your account"),
        react_1.default.createElement("div", { className: "flex flex-col gap-y-4" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: user.name, errorText: nameMessage, onChange: function (v) { return onControlChanged("name", v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: user.surname, errorText: surnameMessage, onChange: function (v) { return onControlChanged("surname", v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: user.email, errorText: emailMessage, onChange: function (v) { return onControlChanged("email", v); } }),
            react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { label: "Phone Number", value: user.cell_number, errorText: phoneMessage, onChange: function (v) { return onControlChanged("cell_number", v); }, onExtensionChange: function (v) { return onControlChanged("cell_country_code", v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Password", value: user.password, errorText: passwordMessage, type: "password", onChange: function (v) { return onControlChanged("password", v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Confirm Password", value: user.confirm_password, errorText: confirmPasswordMessage, type: "password", onChange: function (v) { return onControlChanged("confirm_password", v); } })),
        react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Receive Communication?", uncheckedColor: "text-primary", onChange: function (v) {
                onControlChanged("get_communication", v);
            } }),
        react_1.default.createElement("div", { className: "flex flex-wrap justify-center items-center" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "To create an account,You must agree to the", size: "small", textColor: "text-black" }),
            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Terms of Service", size: "small", textColor: "text-primary", onClick: function () {
                    dispatch((0, main_1.setDrawerOpen)(false));
                    navigate("/terms-and-conditions");
                } })),
        react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "I have read and agree to the Terms of Service", uncheckedColor: "text-primary", errorText: agreesMessage, onChange: function (v) { return onControlChanged("agrees_terms_and_conditions", v); } }),
        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create Account", backgroundColor: "bg-primary", loading: user.is_loading, onClick: function () {
                // if (validate()) {
                generate_otp();
                //  }
            } }),
        react_1.default.createElement("div", { className: "text-center" },
            "Already have an account?",
            " ",
            react_1.default.createElement("span", { className: "hover:underline cursor-pointer text-primary", onClick: function () {
                    dispatch((0, main_1.setDrawerPage)("login"));
                } }, "Sign in"))))));
};
exports.SignUpPage = SignUpPage;
