"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BecomeFranchisePage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var franchise_notification_1 = require("../../../api/calls/notification/franchise_notification");
var become_franchise_templates_1 = require("./become_franchise_templates");
var control_colors_1 = require("../../../constants/control_colors");
var BecomeFranchisePage = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(""), selectedForm = _a[0], setSelectedForm = _a[1];
    var _b = (0, react_1.useState)(false), isButtonLoading = _b[0], setIsButtonLoading = _b[1];
    //Enquiry_Form_Existing_Premises
    var _c = (0, react_1.useState)(""), nameOfPremises = _c[0], setNameOfPremises = _c[1];
    var _d = (0, react_1.useState)(""), addressOfPremises = _d[0], setAddressOfPremises = _d[1];
    var _e = (0, react_1.useState)(""), anchorStores = _e[0], setAnchoStores = _e[1];
    var _f = (0, react_1.useState)(""), anchorStores2 = _f[0], setAnchoStores2 = _f[1];
    var _g = (0, react_1.useState)(""), agentName = _g[0], setAgentName = _g[1];
    var _h = (0, react_1.useState)(""), agentEmail = _h[0], setAgentEmail = _h[1];
    var _j = (0, react_1.useState)(""), agentPhone = _j[0], setAgentPhone = _j[1];
    var _k = (0, react_1.useState)(""), agentTel = _k[0], setAgentTel = _k[1];
    var _l = (0, react_1.useState)(""), shopNumber = _l[0], setShopNumber = _l[1];
    var _m = (0, react_1.useState)(""), shopSize = _m[0], setShopSize = _m[1];
    var _o = (0, react_1.useState)(""), nameOfPremisesError = _o[0], setNameOfPremisesError = _o[1];
    var _p = (0, react_1.useState)(""), addressOfPremisesError = _p[0], setAddressOfPremisesError = _p[1];
    var _q = (0, react_1.useState)(""), anchorStoresError = _q[0], setAnchoStoresError = _q[1];
    var _r = (0, react_1.useState)(""), anchorStores2Error = _r[0], setAnchoStores2Error = _r[1];
    var _s = (0, react_1.useState)(""), agentNameError = _s[0], setAgentNameError = _s[1];
    var _t = (0, react_1.useState)(""), agentEmailError = _t[0], setAgentEmailError = _t[1];
    var _u = (0, react_1.useState)(""), agentPhoneError = _u[0], setAgentPhoneError = _u[1];
    var _v = (0, react_1.useState)(""), agentTelError = _v[0], setAgentTelError = _v[1];
    var _w = (0, react_1.useState)(""), shopNumberError = _w[0], setShopNumberError = _w[1];
    var _x = (0, react_1.useState)(""), shopSizeError = _x[0], setShopSizeError = _x[1];
    //Enquiry_Form_New_Development
    var _y = (0, react_1.useState)(""), nameOfDevelopment = _y[0], setNameOfDevelopment = _y[1];
    var _z = (0, react_1.useState)(""), addressOfDevelopment = _z[0], setAddressOfDevelopment = _z[1];
    var _0 = (0, react_1.useState)(""), nameOfDevelopmentError = _0[0], setNameOfDevelopmentError = _0[1];
    var _1 = (0, react_1.useState)(""), addressOfDevelopmentError = _1[0], setAddressOfDevelopmentError = _1[1];
    var _2 = (0, react_1.useState)("No"), franchiseeOperator = _2[0], setFranchiseeOperator = _2[1];
    var _3 = (0, react_1.useState)("No"), findFranchiseeOperator = _3[0], setFindFranchiseeOperator = _3[1];
    var _4 = (0, react_1.useState)("No"), developmentBusinessPlan = _4[0], setDevelopmentBusinessPlan = _4[1];
    var _5 = (0, react_1.useState)(), developmentOpeningDate = _5[0], setDevelopmentOpeningDate = _5[1];
    var _6 = (0, react_1.useState)(""), developmentOpeningDateError = _6[0], setDevelopmentOpeningDateError = _6[1];
    var _7 = (0, react_1.useState)(""), agentPostalAddress = _7[0], setAgentPostalAddress = _7[1];
    var _8 = (0, react_1.useState)(""), agentPostalAddressError = _8[0], setAgentPostalAddressError = _8[1];
    var _9 = (0, react_1.useState)(""), agentPhysicalAddress = _9[0], setAgentPhysicalAddress = _9[1];
    var _10 = (0, react_1.useState)(""), agentPhysicalAddressError = _10[0], setAgentPhysicalAddressError = _10[1];
    //Enquiry_Form_PotentialOperator
    var _11 = (0, react_1.useState)(""), name = _11[0], setName = _11[1];
    var _12 = (0, react_1.useState)(""), email = _12[0], setEmail = _12[1];
    var _13 = (0, react_1.useState)(""), surname = _13[0], setSurname = _13[1];
    var _14 = (0, react_1.useState)(""), pharmacyNumber = _14[0], setPharmacyNumber = _14[1];
    var _15 = (0, react_1.useState)(""), pharmacyNumberError = _15[0], setPharmacyNumberError = _15[1];
    var _16 = (0, react_1.useState)(""), phone = _16[0], setPhone = _16[1];
    var _17 = (0, react_1.useState)(""), pharmacistNumber = _17[0], setPharmacistNumber = _17[1];
    var _18 = (0, react_1.useState)("No"), pharmacist = _18[0], setPharmacist = _18[1];
    var _19 = (0, react_1.useState)("No"), owner = _19[0], setOwner = _19[1];
    var _20 = (0, react_1.useState)("No"), responsibleRharmacist = _20[0], setResponsibleRharmacist = _20[1];
    var _21 = (0, react_1.useState)("No"), potentialPremises = _21[0], setPotentialPremises = _21[1];
    var _22 = (0, react_1.useState)("No"), fullTime = _22[0], setFullTime = _22[1];
    var _23 = (0, react_1.useState)(""), businessAddress = _23[0], setBusinessAddress = _23[1];
    var _24 = (0, react_1.useState)(""), businessEmail = _24[0], setBusinessEmail = _24[1];
    var _25 = (0, react_1.useState)(""), businessPhoneNumber = _25[0], setBusinessPhoneNumber = _25[1];
    var _26 = (0, react_1.useState)(""), businessEmployeesCount = _26[0], setBusinessEmployeesCount = _26[1];
    var _27 = (0, react_1.useState)(""), businessSize = _27[0], setBusinessSize = _27[1];
    var _28 = (0, react_1.useState)(""), nameError = _28[0], setNameError = _28[1];
    var _29 = (0, react_1.useState)(""), emailError = _29[0], setEmailError = _29[1];
    var _30 = (0, react_1.useState)(""), surnameError = _30[0], setSurnameError = _30[1];
    var _31 = (0, react_1.useState)(""), phoneError = _31[0], setPhoneError = _31[1];
    var _32 = (0, react_1.useState)(""), pharmacistNumberError = _32[0], setPharmacistNumberError = _32[1];
    var _33 = (0, react_1.useState)(""), businessAddressError = _33[0], setBusinessAddressError = _33[1];
    var _34 = (0, react_1.useState)(""), businessEmailError = _34[0], setBusinessEmailError = _34[1];
    var _35 = (0, react_1.useState)(""), businessPhoneNumberError = _35[0], setBusinessPhoneNumberError = _35[1];
    var _36 = (0, react_1.useState)(""), businessEmployeesCountError = _36[0], setBusinessEmployeesCountError = _36[1];
    var _37 = (0, react_1.useState)(""), businessSizeError = _37[0], setBusinessSizeError = _37[1];
    var _38 = (0, react_1.useState)("No"), cashContributions = _38[0], setCashContributions = _38[1];
    //Enquiry_Form_Conversion
    var _39 = (0, react_1.useState)(""), telNumber = _39[0], setTelNumber = _39[1];
    var _40 = (0, react_1.useState)(""), telNumberError = _40[0], setTelNumberError = _40[1];
    var _41 = (0, react_1.useState)(""), physicalAddress = _41[0], setPhysicalAddress = _41[1];
    var _42 = (0, react_1.useState)(""), physicalAddressError = _42[0], setPhysicalAddressError = _42[1];
    var _43 = (0, react_1.useState)(""), postalAddress = _43[0], setPostalAddress = _43[1];
    var _44 = (0, react_1.useState)(""), postalAddressError = _44[0], setPostalAddressError = _44[1];
    var _45 = (0, react_1.useState)(""), businessName = _45[0], setBusinessName = _45[1];
    var _46 = (0, react_1.useState)(""), businessNameError = _46[0], setBusinessNameError = _46[1];
    var _47 = (0, react_1.useState)(""), businessTelNumber = _47[0], setBusinessTelNumber = _47[1];
    var _48 = (0, react_1.useState)(""), businessTelNumberError = _48[0], setBusinessTelNumberError = _48[1];
    var _49 = (0, react_1.useState)("No"), cjAccount = _49[0], setCJAccount = _49[1];
    var _50 = (0, react_1.useState)(""), cjAccountNumber = _50[0], setCJAccountNumber = _50[1];
    var _51 = (0, react_1.useState)(""), cjAccountNumberError = _51[0], setCJAccountNumberError = _51[1];
    var _52 = (0, react_1.useState)(""), softwareVendor = _52[0], setSoftwareVendor = _52[1];
    var _53 = (0, react_1.useState)(""), softwareVendorError = _53[0], setSoftwareVendorError = _53[1];
    var _54 = (0, react_1.useState)(""), numberStaff = _54[0], setNumberStaff = _54[1];
    var _55 = (0, react_1.useState)(""), numberStaffError = _55[0], setNumberStaffError = _55[1];
    var _56 = (0, react_1.useState)(""), orderingVendor = _56[0], setOrderingVendor = _56[1];
    var _57 = (0, react_1.useState)(""), orderingVendorError = _57[0], setOrderingVendorError = _57[1];
    var _58 = (0, react_1.useState)(""), dispensaryTurnover = _58[0], setDispensaryTurnover = _58[1];
    var _59 = (0, react_1.useState)(""), dispensaryTurnoverError = _59[0], setDispensaryTurnoverError = _59[1];
    var _60 = (0, react_1.useState)(""), monthlyTurnover = _60[0], setMonthlyTurnover = _60[1];
    var _61 = (0, react_1.useState)(""), monthlyTurnoverError = _61[0], setMonthlyTurnoverError = _61[1];
    var _62 = (0, react_1.useState)(""), stockHoldingValue = _62[0], setStockHoldingValue = _62[1];
    var _63 = (0, react_1.useState)(""), stockHoldingValueError = _63[0], setStockHoldingValueError = _63[1];
    ///
    var _64 = (0, react_1.useState)({
        franchiseeOperator: "No",
        cashContributions: "No",
        findFranchiseeOperator: "No",
        developmentBusinessPlan: "No",
        fullTime: "No",
        potentialPremises: "No",
        responsibleRharmacist: "No",
        owner: "No",
        pharmacist: "No",
        cjAccount: "No",
    }), formFieldsData = _64[0], setFormFieldsData = _64[1];
    var formData = (0, react_1.useState)([
        {
            label: "Existing Premises",
            sub_title: "I have an existing building to convert into a TLC pharmacy.",
            value: "Enquiry_Form_Existing_Premises",
        },
        {
            label: "New Development",
            sub_title: "I wish to start a new TLC pharmacy from scratch.",
            value: "Enquiry_Form_New_Development",
        },
        {
            label: "Potential Operator",
            sub_title: "I wish to operate a TLC pharmacy without owning a building.",
            value: "Enquiry_Form_PotentialOperator",
        },
        {
            label: "Conversion",
            sub_title: "I wish to convert my existing pharmacy into a TLC pharmacy.",
            value: "Enquiry_Form_Conversion",
        },
    ])[0];
    (0, react_1.useEffect)(function () {
        //Set Defaults when form changes
        setFormFieldsData({
            franchiseeOperator: "No",
            cashContributions: "No",
            findFranchiseeOperator: "No",
            developmentBusinessPlan: "No",
            fullTime: "No",
            potentialPremises: "No",
            responsibleRharmacist: "No",
            owner: "No",
            pharmacist: "No",
            cjAccount: "No",
        });
    }, [selectedForm]);
    var sendApplication = function () { return __awaiter(void 0, void 0, void 0, function () {
        var selectedFormLabel, inputData, data, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 4, , 5]);
                    if (!validateForm()) return [3 /*break*/, 3];
                    setIsButtonLoading(true);
                    selectedFormLabel = (_a = formData.find(function (obj) { return obj.value === selectedForm; })) === null || _a === void 0 ? void 0 : _a.label;
                    return [4 /*yield*/, (0, become_franchise_templates_1.formDataTemplates)(formFieldsData, selectedForm, selectedFormLabel)];
                case 1:
                    inputData = _b.sent();
                    return [4 /*yield*/, (0, franchise_notification_1.send_email_notification)({
                            subject: "Website Franchise Contact Form",
                            content: inputData,
                        })];
                case 2:
                    data = _b.sent();
                    if (data.success) {
                        setIsButtonLoading(false);
                        clearFormData();
                        dispatch((0, main_1.setMessageModal)({
                            title: "Form Submitted!",
                            messages: [data === null || data === void 0 ? void 0 : data.message],
                        }));
                    }
                    else {
                        setIsButtonLoading(false);
                        dispatch((0, main_1.setMessageModal)({
                            title: "Something went wrong!",
                            messages: [data === null || data === void 0 ? void 0 : data.message],
                        }));
                    }
                    _b.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_1 = _b.sent();
                    // Handle errors gracefully here.
                    console.error("An error occurred:", error_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var validateForm = function () {
        var validator = true;
        if (selectedForm == "Enquiry_Form_Existing_Premises") {
            if (!nameOfPremises) {
                setNameOfPremisesError("Please enter name of premises");
                validator = false;
            }
            if (!addressOfPremises) {
                setAddressOfPremisesError("Please enter address of premises");
                validator = false;
            }
            if (!anchorStores) {
                setAnchoStoresError("Please enter anchor stores");
                validator = false;
            }
            if (!anchorStores2 && developmentBusinessPlan == "No") {
                setAnchoStores2Error("Please enter anchor stores");
                validator = false;
            }
            if (!agentName) {
                setAgentNameError("Please enter agent name");
                validator = false;
            }
            if (!agentEmail) {
                setAgentEmailError("Please enter agent email");
                validator = false;
            }
            if (!agentPhone) {
                setAgentPhoneError("Please enter agent phone");
                validator = false;
            }
            if (!shopNumber) {
                setShopNumberError("Please enter shop number");
                validator = false;
            }
            if (!shopSize) {
                setShopSizeError("Please enter shop size");
                validator = false;
            }
        }
        if (selectedForm == "Enquiry_Form_New_Development") {
            if (!nameOfDevelopment) {
                setNameOfDevelopmentError("Please enter name of development");
                validator = false;
            }
            if (!addressOfDevelopment) {
                setAddressOfDevelopmentError("Please enter address of development");
                validator = false;
            }
            if (!anchorStores) {
                setAnchoStoresError("Please enter anchor stores");
                validator = false;
            }
            if (!developmentOpeningDate) {
                setDevelopmentOpeningDateError("Please enter a valid date ");
                validator = false;
            }
            if (!agentName) {
                setAgentNameError("Please enter agent name");
                validator = false;
            }
            if (!agentEmail) {
                setAgentEmailError("Please enter agent email");
                validator = false;
            }
            if (!agentPhone) {
                setAgentPhoneError("Please enter agent phone");
                validator = false;
            }
            if (!shopNumber) {
                setShopNumberError("Please enter shop number");
                validator = false;
            }
            if (!shopSize) {
                setShopSizeError("Please enter shop size");
                validator = false;
            }
        }
        if (selectedForm == "Enquiry_Form_PotentialOperator") {
            if (!phone) {
                setPhoneError("Please enter phone");
                validator = false;
            }
            if (!email) {
                setEmailError("Please enter email");
                validator = false;
            }
            if (!telNumber) {
                setTelNumberError("Please enter telephone number");
                validator = false;
            }
            if (!businessAddress) {
                setBusinessAddressError("Please enter address");
                validator = false;
            }
            if (!businessEmail) {
                setBusinessEmailError("Please enter email");
                validator = false;
            }
            if (!businessEmployeesCount) {
                setBusinessEmployeesCountError("Please enter employees count");
                validator = false;
            }
            if (!businessSize) {
                setBusinessSizeError("Please enter size");
                validator = false;
            }
            if (!businessPhoneNumber) {
                setBusinessPhoneNumberError("Please enter cellphone number");
                validator = false;
            }
            if (pharmacist == "Yes") {
                if (!pharmacistNumber) {
                    setPharmacistNumberError("Please enter pharmacist number");
                    validator = false;
                }
            }
        }
        if (selectedForm == "Enquiry_Form_Conversion") {
            if (!name) {
                setNameError("Please enter name");
                validator = false;
            }
            if (!phone) {
                setPhoneError("Please enter phone");
                validator = false;
            }
            if (!email) {
                setEmailError("Please enter email");
                validator = false;
            }
            if (!businessAddress) {
                setBusinessAddressError("Please enter address");
                validator = false;
            }
            if (!businessEmail) {
                setBusinessEmailError("Please enter email");
                validator = false;
            }
            if (!businessSize) {
                setBusinessSizeError("Please enter size");
                validator = false;
            }
            if (!businessPhoneNumber) {
                setBusinessPhoneNumberError("Please enter cellphone number");
                validator = false;
            }
            if (!telNumber) {
                setTelNumberError("Please enter telephone number");
                validator = false;
            }
            if (!physicalAddress) {
                setPhysicalAddressError("Please enter physical address");
                validator = false;
            }
            if (!postalAddress) {
                setPostalAddressError("Please enter postal address");
                validator = false;
            }
            if (!businessName) {
                setBusinessNameError("Please enter business name");
                validator = false;
            }
            if (!businessTelNumber) {
                setBusinessTelNumberError("Please enter business telephone number");
                validator = false;
            }
            if (cjAccount == "Yes") {
                if (!cjAccountNumber) {
                    setCJAccountNumberError("Please enter CJ account number");
                    validator = false;
                }
            }
            if (!softwareVendor) {
                setSoftwareVendorError("Please enter software vendor");
                validator = false;
            }
            if (!numberStaff) {
                setNumberStaffError("Please enter number of staff");
                validator = false;
            }
            if (!orderingVendor) {
                setOrderingVendorError("Please enter ordering vendor");
                validator = false;
            }
            if (!dispensaryTurnover) {
                setDispensaryTurnoverError("Please enter dispensary turnover");
                validator = false;
            }
            if (!monthlyTurnover) {
                setMonthlyTurnoverError("Please enter monthly turnover");
                validator = false;
            }
            if (!stockHoldingValue) {
                setStockHoldingValueError("Please enter stock holding value");
                validator = false;
            }
        }
        return validator;
    };
    var clearFormData = function () {
        // Default values
        var defaultString = "";
        var defaultDate = null;
        var defaultBool = "No";
        // Set the values
        setNameOfPremises(defaultString);
        setAddressOfPremises(defaultString);
        setAnchoStores(defaultString);
        setAgentName(defaultString);
        setAgentEmail(defaultString);
        setAgentPhone(defaultString);
        setAgentTel(defaultString);
        setShopNumber(defaultString);
        setShopSize(defaultString);
        setNameOfPremisesError(defaultString);
        setAddressOfPremisesError(defaultString);
        setAnchoStoresError(defaultString);
        setAgentNameError(defaultString);
        setAgentEmailError(defaultString);
        setAgentPhoneError(defaultString);
        setAgentTelError(defaultString);
        setShopNumberError(defaultString);
        setShopSizeError(defaultString);
        setNameOfDevelopment(defaultString);
        setAddressOfDevelopment(defaultString);
        setNameOfDevelopmentError(defaultString);
        setAddressOfDevelopmentError(defaultString);
        setFranchiseeOperator(defaultBool);
        setFindFranchiseeOperator(defaultBool);
        setDevelopmentBusinessPlan(defaultBool);
        setDevelopmentOpeningDate(defaultDate);
        setDevelopmentOpeningDateError(defaultString);
        setAgentPostalAddress(defaultString);
        setAgentPostalAddressError(defaultString);
        setAgentPhysicalAddress(defaultString);
        setAgentPhysicalAddressError(defaultString);
        setPharmacyNumber(defaultString);
        setAnchoStores2(defaultString);
        setPharmacyNumberError(defaultString);
        setAnchoStores2Error(defaultString);
        setName(defaultString);
        setEmail(defaultString);
        setSurname(defaultString);
        setPhone(defaultString);
        setPharmacistNumber(defaultString);
        setPharmacist(defaultBool);
        setOwner(defaultBool);
        setResponsibleRharmacist(defaultBool);
        setPotentialPremises(defaultBool);
        setFullTime(defaultBool);
        setBusinessAddress(defaultString);
        setBusinessEmail(defaultString);
        setBusinessPhoneNumber(defaultString);
        setBusinessEmployeesCount(defaultString);
        setBusinessSize(defaultString);
        setNameError(defaultString);
        setEmailError(defaultString);
        setSurnameError(defaultString);
        setPhoneError(defaultString);
        setPharmacistNumberError(defaultString);
        setBusinessAddressError(defaultString);
        setBusinessEmailError(defaultString);
        setBusinessPhoneNumberError(defaultString);
        setBusinessEmployeesCountError(defaultString);
        setBusinessSizeError(defaultString);
        setCashContributions(defaultBool);
        setTelNumber(defaultString);
        setTelNumberError(defaultString);
        setPhysicalAddress(defaultString);
        setPhysicalAddressError(defaultString);
        setPostalAddress(defaultString);
        setPostalAddressError(defaultString);
        setBusinessName(defaultString);
        setBusinessNameError(defaultString);
        setBusinessTelNumber(defaultString);
        setBusinessTelNumberError(defaultString);
        setCJAccount(defaultBool);
        setCJAccountNumber(defaultString);
        setCJAccountNumberError(defaultString);
        setSoftwareVendor(defaultString);
        setSoftwareVendorError(defaultString);
        setNumberStaff(defaultString);
        setNumberStaffError(defaultString);
        setOrderingVendor(defaultString);
        setOrderingVendorError(defaultString);
        setDispensaryTurnover(defaultString);
        setDispensaryTurnoverError(defaultString);
        setMonthlyTurnover(defaultString);
        setMonthlyTurnoverError(defaultString);
        setStockHoldingValue(defaultString);
        setStockHoldingValueError(defaultString);
    };
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2 items-center" },
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: formData, borderColor: "border-[#EEEEEE]", errorTextColor: "text-red-500", label: "Please choose the most appropriate form", value: selectedForm === null || selectedForm === void 0 ? void 0 : selectedForm.title, onChange: function (v) {
                            setSelectedForm(v);
                        } })),
                react_1.default.createElement("div", { className: "" },
                    selectedForm == "Enquiry_Form_Existing_Premises" ? (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "bg-white shadow-lg", variant: "filled", addPadding: false },
                        react_1.default.createElement("div", { className: "p-8 flex flex-col gap-4" },
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Existing Premises Information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name of premises:", borderColor: "border-borders", value: nameOfPremises, errorText: nameOfPremisesError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { nameOfPremises: v }));
                                            setNameOfPremises(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Physical address of existing premises:", borderColor: "border-borders", value: addressOfPremises, errorText: addressOfPremisesError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { addressOfPremises: v }));
                                            setAddressOfPremises(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Anchor stores:", borderColor: "border-borders", value: anchorStores, errorText: anchorStoresError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { anchorStores: v }));
                                            setAnchoStores(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement("div", null,
                                        react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have a development business plan & market research available?", colors: control_colors_1.radio_colors, selectedValue: developmentBusinessPlan, options: [
                                                { label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" },
                                            ], onChange: function (value) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { developmentBusinessPlan: value }));
                                                setDevelopmentBusinessPlan(value);
                                            } }),
                                        developmentBusinessPlan == "Yes" ? (react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-primary", bold: true, size: "medium", label: "Please share this via email to care@thelocalchoice.net" })) : ("")))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Developer / Agent Information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name of Developer/Agent:", borderColor: "border-borders", value: agentName, errorText: agentEmailError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { agentName: v }));
                                            setAgentName(v);
                                        } }),
                                    react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Telephone Number:", borderColor: "border-borders", value: agentTel, errorText: agentTelError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { agentTel: v }));
                                                setAgentTel(v);
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cellphone Number:", borderColor: "border-borders", value: agentPhone, errorText: agentPhoneError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { agentPhone: v }));
                                                setAgentPhone(v);
                                            } })),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email address:", borderColor: "border-borders", value: agentEmail, errorText: agentEmailError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { agentEmail: v }));
                                            setAgentEmail(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Physical address of Developer/Agent:", borderColor: "border-borders", value: agentPhysicalAddress, errorText: agentPhysicalAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { agentPhysicalAddress: v }));
                                            setAgentPhysicalAddress(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Postal address of Developer/Agent:", borderColor: "border-borders", value: agentPostalAddress, errorText: agentPostalAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { agentPostalAddress: v }));
                                            setAgentPostalAddress(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Shop Information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Proposed Shop Number:", borderColor: "border-borders", value: shopNumber, errorText: shopNumberError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { shopNumber: v }));
                                            setShopNumber(v);
                                        } }),
                                    developmentBusinessPlan == "No" ? (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Anchor stores:", borderColor: "border-borders", value: anchorStores2, errorText: anchorStores2Error, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { anchorStores2: v }));
                                            setAnchoStores2(v);
                                        } })) : (""),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Proposed Shop Size:", borderColor: "border-borders", value: shopSize, errorText: shopSizeError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { shopSize: v }));
                                            setShopSize(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have a possible person who wishes to act as a franchisee or operator?", colors: control_colors_1.radio_colors, selectedValue: franchiseeOperator, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { franchiseeOperator: value }));
                                            setFranchiseeOperator(value);
                                        } })),
                                react_1.default.createElement("div", null, developmentBusinessPlan == "No" ? (react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you require The Local Choice to find a possible franchisee/operator?", colors: control_colors_1.radio_colors, selectedValue: findFranchiseeOperator, options: [
                                        { label: "Yes", value: "Yes" },
                                        { label: "No", value: "No" },
                                    ], onChange: function (value) {
                                        setFormFieldsData(__assign(__assign({}, formFieldsData), { findFranchiseeOperator: value }));
                                        setFindFranchiseeOperator(value);
                                    } })) : (""))),
                            react_1.default.createElement("div", { className: "flex flex-row justify-start mt-8" },
                                react_1.default.createElement("div", { className: "w-full md:w-44" },
                                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Submit", backgroundColor: "bg-primary", loading: isButtonLoading, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                sendApplication();
                                                return [2 /*return*/];
                                            });
                                        }); } })))))) : (""),
                    selectedForm == "Enquiry_Form_New_Development" ? (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "bg-white shadow-lg", variant: "filled", addPadding: false },
                        react_1.default.createElement("div", { className: "p-8 flex flex-col gap-4" },
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "New Development Information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name of development:", borderColor: "border-borders", value: nameOfDevelopment, errorText: nameOfDevelopmentError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { nameOfDevelopment: v }));
                                            setNameOfDevelopment(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Physical address of new development:", borderColor: "border-borders", value: addressOfDevelopment, errorText: addressOfDevelopmentError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { addressOfDevelopment: v }));
                                            setAddressOfDevelopment(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "Development Opening Date:", borderColor: "border-borders", inputFormat: "DD/MM/YYYY", value: developmentOpeningDate, errorText: developmentOpeningDateError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { developmentOpeningDate: v }));
                                            setDevelopmentOpeningDate(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Anchor Stores:", borderColor: "border-borders", value: anchorStores, errorText: anchorStoresError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { anchorStores: v }));
                                            setAnchoStores(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement("div", null,
                                        react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have a development business plan & market research available?", colors: control_colors_1.radio_colors, selectedValue: developmentBusinessPlan, options: [
                                                { label: "Yes", value: "Yes" },
                                                { label: "No", value: "No" },
                                            ], onChange: function (value) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { developmentBusinessPlan: value }));
                                                setDevelopmentBusinessPlan(value);
                                            } }),
                                        developmentBusinessPlan == "Yes" ? (react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-primary", bold: true, size: "medium", label: "Please share this via email to care@thelocalchoice.net" })) : ("")))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Developer / Agent Information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name of Developer/Agent", borderColor: "border-borders", value: agentName, errorText: agentNameError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { agentName: v }));
                                            setAgentName(v);
                                        } }),
                                    react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Telephone Number:", borderColor: "border-borders", value: agentTel, errorText: agentTelError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { agentTel: v }));
                                                setAgentTel(v);
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cellphone Number:", borderColor: "border-borders", value: agentPhone, errorText: agentPhoneError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { agentPhone: v }));
                                                setAgentPhone(v);
                                            } })),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email address:", borderColor: "border-borders", value: agentEmail, errorText: agentEmailError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { agentEmail: v }));
                                            setAgentEmail(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Physical address of Developer/Agent:", borderColor: "border-borders", value: agentPhysicalAddress, errorText: agentPhysicalAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { agentPhysicalAddress: v }));
                                            setAgentPhysicalAddress(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Postal address of Developer/Agent:", borderColor: "border-borders", value: agentPostalAddress, errorText: agentPostalAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { agentPostalAddress: v }));
                                            setAgentPostalAddress(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Shop Information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Proposed Shop Number", borderColor: "border-borders", value: shopNumber, errorText: shopNumberError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { shopNumber: v }));
                                            setShopNumber(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Proposed Shop Size", borderColor: "border-borders", value: shopSize, errorText: shopSizeError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { shopSize: v }));
                                            setShopSize(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have a possible person who wishes to act as a franchisee or operator?", colors: control_colors_1.radio_colors, selectedValue: franchiseeOperator, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { franchiseeOperator: value }));
                                            setFranchiseeOperator(value);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-row justify-start mt-8" },
                                react_1.default.createElement("div", { className: "w-full md:w-44" },
                                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Submit", backgroundColor: "bg-primary", loading: isButtonLoading, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                sendApplication();
                                                return [2 /*return*/];
                                            });
                                        }); } })))))) : (""),
                    selectedForm == "Enquiry_Form_PotentialOperator" ? (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "bg-white shadow-lg", variant: "filled", addPadding: false },
                        react_1.default.createElement("div", { className: "p-8 flex flex-col gap-4" },
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Personal Information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name and Surname:", borderColor: "border-borders", value: name, errorText: nameError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { name: v }));
                                            setName(v);
                                        } }),
                                    react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Telephone Number:", borderColor: "border-borders", value: telNumber, errorText: telNumberError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { telNumber: v }));
                                                setTelNumber(v);
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cellphone Number:", borderColor: "border-borders", value: phone, errorText: phoneError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { phone: v }));
                                                setPhone(v);
                                            } })),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email address:", borderColor: "border-borders", value: email, errorText: emailError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { email: v }));
                                            setEmail(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Physical Address", borderColor: "border-borders", value: physicalAddress, errorText: physicalAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { physicalAddress: v }));
                                            setPhysicalAddress(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Postal Address", borderColor: "border-borders", value: postalAddress, errorText: postalAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { postalAddress: v }));
                                            setPostalAddress(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Are you the owner of the business: ", colors: control_colors_1.radio_colors, selectedValue: owner, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { owner: value }));
                                            setOwner(value);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Are you a pharmacist?", colors: control_colors_1.radio_colors, selectedValue: pharmacist, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { pharmacist: value }));
                                            setPharmacist(value);
                                        } }),
                                    pharmacist == "No" ? (react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have a responsible pharmacist?", colors: control_colors_1.radio_colors, selectedValue: responsibleRharmacist, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { responsibleRharmacist: value }));
                                            setResponsibleRharmacist(value);
                                        } })) : null,
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Will you be at the pharmacy full-time?", colors: control_colors_1.radio_colors, selectedValue: fullTime, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { fullTime: value }));
                                            setFullTime(value);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have a potential premise available?", colors: control_colors_1.radio_colors, selectedValue: potentialPremises, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { potentialPremises: value }));
                                            setPotentialPremises(value);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Business Information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Pharmacy Name", borderColor: "border-borders", value: businessName, errorText: businessNameError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { businessName: v }));
                                            setBusinessName(v);
                                        } }),
                                    react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Pharmacy Number:", borderColor: "border-borders", value: pharmacyNumber, errorText: pharmacyNumberError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { pharmacyNumber: v }));
                                                setPharmacyNumber(v);
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cellphone Number:", borderColor: "border-borders", value: businessPhoneNumber, errorText: businessPhoneNumberError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { businessPhoneNumber: v }));
                                                setBusinessPhoneNumber(v);
                                            } })),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email Address", borderColor: "border-borders", value: businessEmail, errorText: businessEmailError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { businessEmail: v }));
                                            setBusinessEmail(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Physical Address:", borderColor: "border-borders", value: businessAddress, errorText: businessAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { businessAddress: v }));
                                            setBusinessAddress(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Size of pharmacy (m\u00B2)", borderColor: "border-borders", value: businessSize, errorText: businessSizeError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { businessSize: v }));
                                            setBusinessSize(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Intended number of staff members:", borderColor: "border-borders", value: businessEmployeesCount, errorText: businessEmployeesCountError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { businessEmployeesCount: v }));
                                            setBusinessEmployeesCount(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have access to cash contributions to invest in converting to a The Local Choice franchise?", colors: control_colors_1.radio_colors, selectedValue: cashContributions, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { cashContributions: value }));
                                            setCashContributions(value);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-row justify-start mt-8" },
                                react_1.default.createElement("div", { className: "w-full md:w-44" },
                                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Submit", backgroundColor: "bg-primary", loading: isButtonLoading, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                sendApplication();
                                                return [2 /*return*/];
                                            });
                                        }); } })))))) : (""),
                    selectedForm == "Enquiry_Form_Conversion" ? (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "bg-white shadow-lg", variant: "filled", addPadding: false },
                        react_1.default.createElement("div", { className: "p-8 flex flex-col gap-4" },
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Personal information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name and Surname:", borderColor: "border-borders", value: name, errorText: nameError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { name: v }));
                                            setName(v);
                                        } }),
                                    react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Telephone Number:", borderColor: "border-borders", value: telNumber, errorText: telNumberError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { telNumber: v }));
                                                setTelNumber(v);
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cellphone Number:", borderColor: "border-borders", value: phone, errorText: phoneError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { phone: v }));
                                                setPhone(v);
                                            } })),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email:", borderColor: "border-borders", value: email, errorText: emailError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { email: v }));
                                            setEmail(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Physical address:", borderColor: "border-borders", value: physicalAddress, errorText: physicalAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { physicalAddress: v }));
                                            setPhysicalAddress(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Postal address:", borderColor: "border-borders", value: postalAddress, errorText: postalAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { postalAddress: v }));
                                            setPostalAddress(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2 " },
                                react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Are you the owner of the pharmacy?", colors: control_colors_1.radio_colors, selectedValue: owner, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { owner: value }));
                                            setOwner(value);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Are you a pharmacist?", colors: control_colors_1.radio_colors, selectedValue: pharmacist, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { pharmacist: value }));
                                            setPharmacist(value);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Business Information", uppercase: true, size: "large", textColor: "text-primary" }),
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Current pharmacy name:", borderColor: "border-borders", value: businessName, errorText: businessNameError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { businessName: v }));
                                            setBusinessName(v);
                                        } }),
                                    react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Telephone Number:", borderColor: "border-borders", value: businessTelNumber, errorText: businessTelNumberError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { businessTelNumber: v }));
                                                setBusinessTelNumber(v);
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cellphone Number:", borderColor: "border-borders", value: businessPhoneNumber, errorText: businessPhoneNumberError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { businessPhoneNumber: v }));
                                                setBusinessPhoneNumber(v);
                                            } })),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email address:", borderColor: "border-borders", value: businessEmail, errorText: businessEmailError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { businessEmail: v }));
                                            setBusinessEmail(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Physical address of current pharmacy:", borderColor: "border-borders", value: businessAddress, errorText: businessAddressError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { businessAddress: v }));
                                            setBusinessAddress(v);
                                        } }))),
                            react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have a CJ Account?", colors: control_colors_1.radio_colors, selectedValue: cjAccount, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { cjAccount: value }));
                                            setCJAccount(value);
                                        } }),
                                    cjAccount == "Yes" ? (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "CJ Account Number", borderColor: "border-borders", value: cjAccountNumber, errorText: cjAccountNumberError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { cjAccountNumber: v }));
                                            setCJAccountNumber(v);
                                        } })) : (""))),
                            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                                react_1.default.createElement("div", { className: "grid gap-4 grid-cols-1" },
                                    react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Size of pharmacy (m\u00B2):", borderColor: "border-borders", value: businessSize, errorText: businessSizeError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { businessSize: v }));
                                                setBusinessSize(v);
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Number of staff members", borderColor: "border-borders", value: numberStaff, errorText: numberStaffError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { numberStaff: v }));
                                                setNumberStaff(v);
                                            } })),
                                    react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 lg:grid-cols-2" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Current software vendor", borderColor: "border-borders", value: softwareVendor, errorText: softwareVendorError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { softwareVendor: v }));
                                                setSoftwareVendor(v);
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Current ordering vendor", borderColor: "border-borders", value: orderingVendor, errorText: orderingVendorError, onChange: function (v) {
                                                setFormFieldsData(__assign(__assign({}, formFieldsData), { orderingVendor: v }));
                                                setOrderingVendor(v);
                                            } })),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Estimated stock-holding value (R)(excl. VAT)", borderColor: "border-borders", value: stockHoldingValue, errorText: stockHoldingValueError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { stockHoldingValue: v }));
                                            setStockHoldingValue(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Current monthly turnover (R)(excl. VAT)", borderColor: "border-borders", value: monthlyTurnover, errorText: monthlyTurnoverError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { monthlyTurnover: v }));
                                            setMonthlyTurnover(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Front shop versus dispensary turnover (%)", borderColor: "border-borders", value: dispensaryTurnover, errorText: dispensaryTurnoverError, onChange: function (v) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { dispensaryTurnover: v }));
                                            setDispensaryTurnover(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Do you have access to cash contributions to invest in converting to a The Local Choice franchise?", colors: control_colors_1.radio_colors, selectedValue: cashContributions, options: [
                                            { label: "Yes", value: "Yes" },
                                            { label: "No", value: "No" },
                                        ], onChange: function (value) {
                                            setFormFieldsData(__assign(__assign({}, formFieldsData), { cashContributions: value }));
                                            setCashContributions(value);
                                        } }))),
                            react_1.default.createElement("div", { className: "flex flex-row justify-start mt-8" },
                                react_1.default.createElement("div", { className: "w-full md:w-44" },
                                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Submit", backgroundColor: "bg-primary", loading: isButtonLoading, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                sendApplication();
                                                return [2 /*return*/];
                                            });
                                        }); } })))))) : (""))))));
};
exports.BecomeFranchisePage = BecomeFranchisePage;
