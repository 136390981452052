"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WhyShopWithUsComponent = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var WhyShopWithUsComponent = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () { }, []);
    return (react_1.default.createElement("div", { className: "flex justify-center w-full" },
        react_1.default.createElement("div", { className: "bg-[#F7F7F7] flex flex-col content-center md:w-8/12" },
            react_1.default.createElement("div", { className: "p-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Why Shop With Us", textColor: "text-black", size: "xlarge", center: true, bold: true })),
            react_1.default.createElement("div", { className: " flex flex-col md:flex-row gap-4 p-4" },
                react_1.default.createElement("div", { className: " flex flex-col p-2 gap-2" },
                    react_1.default.createElement("div", { className: "flex flex-row gap-2" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTruck }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Fast Delivery", textColor: "text-black", size: "small", bold: true })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Certain items delivered within 5 hours! ", textColor: "text-gray", size: "xsmall" }))),
                react_1.default.createElement("div", { className: " flex flex-col p-2 gap-2" },
                    react_1.default.createElement("div", { className: "flex flex-row gap-2" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCube }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Convenient Click & Collect", textColor: "text-black", size: "small", bold: true })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Fast and easy collection at your nearest Local Choice Pharmacy.", textColor: "text-gray", size: "xsmall" }))),
                react_1.default.createElement("div", { className: " flex flex-col p-2 gap-2" },
                    react_1.default.createElement("div", { className: "flex flex-row gap-2" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPersonWalkingArrowLoopLeft }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Easy Returns", textColor: "text-black", size: "small", bold: true })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "No hassle with your returns. Take it back to your store!", textColor: "text-gray", size: "xsmall" }),
                        react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: " Ts & Cs apply.", textColor: "text-link", onClick: function () {
                                navigate("/terms-and-conditions");
                            }, size: "xsmall" })))))));
};
exports.WhyShopWithUsComponent = WhyShopWithUsComponent;
