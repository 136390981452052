"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileBankCardsWidget = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var delete_card_vault_1 = require("../../../api/calls/payment-engine/paygate/delete_card_vault");
var get_card_vault_1 = require("../../../api/calls/payment-engine/paygate/get_card_vault");
var main_1 = require("../../../reducers/slices/main");
var ProfileBankCardsWidget = function (props) {
    var _a = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_1.useState)(false), isEditing = _b[0], setIsEditing = _b[1];
    var _c = (0, react_1.useState)(null), selectedPlaceDetails = _c[0], setSelectedPlaceDetails = _c[1];
    var _d = (0, react_1.useState)(), selectedAddress = _d[0], setSelectedAddress = _d[1];
    var _e = (0, react_1.useState)([]), bankCardList = _e[0], setBankCardList = _e[1];
    var _f = (0, react_1.useState)(""), newAddressName = _f[0], setNewAddressName = _f[1];
    var _g = (0, react_1.useState)(""), addressLabelErrorText = _g[0], setAddressLabelErrorText = _g[1];
    var _h = (0, react_1.useState)(-1), deletingIndex = _h[0], setDeletingIndex = _h[1];
    var _j = (0, react_1.useState)(false), isSaving = _j[0], setIsSaving = _j[1];
    var _k = (0, react_1.useState)(false), isDeleting = _k[0], setIsDeleting = _k[1];
    (0, react_1.useEffect)(function () {
        getBankCards();
    }, []);
    var getBankCards = function () {
        var _a;
        (0, get_card_vault_1.get_card_vault)({
            integration_id: (_a = user._id) !== null && _a !== void 0 ? _a : "",
        }).then(function (response) {
            if (response.success) {
                setBankCardList(response.content.result);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
            }
        });
    };
    var validateAddress = function () {
        var messages = [];
        var errorCount = 0;
        setAddressLabelErrorText("");
        if (!selectedAddress) {
            messages.push("Please select an address. When you searched, please click on a prediction to select it.");
        }
        if (!newAddressName) {
            errorCount++;
            setAddressLabelErrorText("Please enter a label for your new address.");
        }
        if (messages.length > 0 || errorCount > 0) {
            if (messages.length > 0) {
                dispatch((0, main_1.setMessageModal)({ title: "Address selection needed!", messages: messages }));
            }
            return false;
        }
        else {
            return true;
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 p-4 border border-borders rounded-lg" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Bank Cards", size: "large", textColor: "text-black" })),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" })),
        react_1.default.createElement("div", { className: "" }, bankCardList && bankCardList.length > 0 ? (bankCardList.map(function (bankCard, index) {
            return (react_1.default.createElement("div", { key: index, className: "".concat(index == 0 ? "" : "border-t border-borders", " mb-2 pt-2 flex flex-row gap-4 items-center") },
                react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: bankCard.card_number, bold: true, textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: bankCard.card_type }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: bankCard.expiry_date })),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrash, backgroundColor: "bg-error", iconColor: "text-white", size: "xsmall", loading: index == deletingIndex && isDeleting, onClick: function () {
                            // TODO: this needs to delete a bank card...
                            setIsDeleting(true);
                            setDeletingIndex(index);
                            (0, delete_card_vault_1.delete_card_vault)({
                                card_id: bankCard.id,
                            }).then(function (response) {
                                setIsDeleting(false);
                                setDeletingIndex(-1);
                                if (response.success) {
                                    getBankCards();
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Something went wrong!",
                                        messages: [response.message],
                                    }));
                                }
                            });
                        } }))));
        })) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You haven't added any bank cards. Bank cards can be saved during checkout." })))));
};
exports.ProfileBankCardsWidget = ProfileBankCardsWidget;
