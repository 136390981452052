"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemesComponent = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var categories_1 = require("../../../../api/calls/product/categories");
var ThemesComponent = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var carouselRef = (0, react_1.useRef)(null);
    var _a = (0, react_1.useState)([]), categories = _a[0], setCategories = _a[1];
    var _b = (0, react_1.useState)([]), themesCarouselItems = _b[0], setThemesCarouselItems = _b[1];
    (0, react_1.useEffect)(function () {
        (0, categories_1.getCategories)().then(function (response) {
            if (response.success) {
                var ls = response.content;
                var shopCategory = ls.find(function (c) { return c.name.toLowerCase() == "theme"; });
                if (shopCategory) {
                    (0, categories_1.getCategories)({
                        // page: "1",
                        parent_id: shopCategory.id,
                    }).then(function (response) {
                        if (response.success) {
                            var newCategoryList = [];
                            for (var _i = 0, _a = response.content; _i < _a.length; _i++) {
                                var category = _a[_i];
                                newCategoryList.push({
                                    id: category.id,
                                    title: category.name,
                                    image_url: category.image_url,
                                    expanded: false,
                                    items: category.sub_categories.map(function (sub_category, index) { return ({
                                        _id: sub_category.id,
                                        title: sub_category.category_name,
                                        image_url: sub_category.image_url,
                                        items: [],
                                    }); }),
                                });
                            }
                            setCategories(newCategoryList);
                        }
                        else {
                            //dispatch(setMessageModal({ title: "Failed to retrieve categories", messages: [response.message] }));
                        }
                    });
                }
            }
            else {
                // dispatch(setMessageModal({ title: "Failed to retrieve root level categories", messages: [response.message] }));
            }
        });
    }, []);
    (0, react_1.useEffect)(function () {
        var sample = [];
        var _loop_1 = function (category) {
            sample.push({
                content: (react_1.default.createElement("div", { className: "flex flex-col justify-center items-center  aspect-square cursor-pointer" },
                    react_1.default.createElement("img", { className: "w-full h-full object-cover absolute top-0 left-0 -z-20", src: category === null || category === void 0 ? void 0 : category.image_url }),
                    react_1.default.createElement("div", { className: "absolute -z-10 h-full w-full top-0 left-0 bg-primary opacity-5" }),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: category === null || category === void 0 ? void 0 : category.title, textColor: "text-white", size: "large", center: true, bold: true })))),
                onClick: function () {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    navigate("/shop/themes/theme/1/".concat(category.id));
                },
            });
        };
        for (var _i = 0, categories_2 = categories; _i < categories_2.length; _i++) {
            var category = categories_2[_i];
            _loop_1(category);
        }
        setThemesCarouselItems(sample);
    }, [categories]);
    return (react_1.default.createElement("div", { className: "w-full" },
        react_1.default.createElement("div", { className: "bg-secondary p-2 flex" },
            react_1.default.createElement("div", { className: "flex gap-2 items-center flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Themes", textColor: "text-white", size: "large", bold: true })),
            react_1.default.createElement("div", { className: "flex items-center justify-between" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "View All", size: "small", backgroundColor: "bg-transparent", onClick: function () {
                        navigate("/themes");
                    } }))),
        react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-12 gap-4 bg-white  rounded-xl p-4" },
            react_1.default.createElement("div", { className: "flex flex-row items-center pb-4" },
                react_1.default.createElement("div", { className: "flex flex-col cursor-pointer", onClick: function () {
                        var _a;
                        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.prev();
                    } },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faChevronCircleLeft, className: "text-primary", size: "2x" }))),
            react_1.default.createElement("div", { className: "flex justify-center items-center md:col-span-10 gap-4" },
                react_1.default.createElement(doshx_controls_web_1.ContentCarouselControl, { shortSwipes: true, ref: carouselRef, heightClasses: "h-96 md:h-60", centerSlides: false, 
                    // viewport={4}
                    breakpoints: {
                        sm: {
                            viewport: 1,
                        },
                        md: {
                            viewport: 4,
                        },
                    }, slideTimeout: 1700, colors: {
                        bulletColor: "bg-white",
                    }, items: themesCarouselItems })),
            react_1.default.createElement("div", { className: "flex flex-row items-center pb-4" },
                react_1.default.createElement("div", { className: "flex flex-col cursor-pointer", onClick: function () {
                        var _a;
                        (_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.next();
                    } },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faChevronCircleRight, className: "text-primary", size: "2x" }))))));
};
exports.ThemesComponent = ThemesComponent;
