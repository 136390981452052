"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewPrescriptionPage = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var get_scripts_1 = require("../../../api/calls/script/get_scripts");
var main_1 = require("../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var ViewPrescriptionPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)([]), userScripts = _a[0], setUserScripts = _a[1];
    var _b = (0, react_1.useState)([]), accordionScripts = _b[0], setAccordionScripts = _b[1];
    var _c = (0, react_1.useState)(true), isLoading = _c[0], setIsLoading = _c[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    (0, react_1.useEffect)(function () {
        (0, get_scripts_1.get_scripts)({
            user_id: user === null || user === void 0 ? void 0 : user._id,
        }).then(function (response) {
            if (response.success) {
                setUserScripts(response === null || response === void 0 ? void 0 : response.content);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong while getting your scripts!", messages: [response.message] }));
            }
        });
        //
    }, [user]);
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d;
        var tempData = [];
        for (var _i = 0, _e = userScripts !== null && userScripts !== void 0 ? userScripts : []; _i < _e.length; _i++) {
            var script = _e[_i];
            tempData.push({
                expanded: false,
                header: (react_1.default.createElement("div", { className: "flex flex-row gap-4 " },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faFile, size: "xl" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: script === null || script === void 0 ? void 0 : script.name, textColor: "text-black", size: "2xlarge" }))),
                content: (react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                    react_1.default.createElement("img", { src: (_a = script === null || script === void 0 ? void 0 : script.file) === null || _a === void 0 ? void 0 : _a.location, className: "max-w-lg" }),
                    react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                        react_1.default.createElement("div", { className: "flex flex-col" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "File Name", textColor: "text-black", size: "medium", bold: true }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: script === null || script === void 0 ? void 0 : script.name, textColor: "text-black", size: "small" })),
                        react_1.default.createElement("div", { className: "flex flex-col" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Instructions", textColor: "text-black", size: "medium", bold: true }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: script === null || script === void 0 ? void 0 : script.description, textColor: "text-black", size: "small" })),
                        react_1.default.createElement("div", { className: "flex flex-col" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Branch", textColor: "text-black", size: "medium", bold: true }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: script === null || script === void 0 ? void 0 : script.branch_name, textColor: "text-black", size: "small" })),
                        react_1.default.createElement("div", { className: "flex flex-col" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Method", textColor: "text-black", size: "medium", bold: true }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_b = script === null || script === void 0 ? void 0 : script.delivery_type) === null || _b === void 0 ? void 0 : _b.toUpperCase(), textColor: "text-black", size: "small" })),
                        react_1.default.createElement("div", { className: "flex flex-col" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Status", textColor: "text-black", size: "medium", bold: true }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_d = (_c = script === null || script === void 0 ? void 0 : script.status) === null || _c === void 0 ? void 0 : _c.toUpperCase()) !== null && _d !== void 0 ? _d : "PENDING", textColor: "text-black", size: "small" }))))),
            });
        }
        setAccordionScripts(tempData);
    }, [userScripts]);
    return (react_1.default.createElement("div", { className: "flex justify-center" }, isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "medium", progressColor: "text-primary" })) : (react_1.default.createElement("div", { className: "shadow-lg w-full" }, userScripts && userScripts.length > 0 ? (react_1.default.createElement(doshx_controls_web_1.AccordionControl, { colors: {
            titleTextColor: "text-black",
            titleBackgroundColor: "bg-gray-100",
            contentTextColor: "text-black",
            contentBackgroundColor: "bg-white",
            borderColor: "border-transparent",
            divideColor: "divide-gray-200",
        }, arrowSide: "right", items: accordionScripts })) : (react_1.default.createElement("div", { className: "p-4 shadow-lg w-full" },
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "No user scripts found.", size: "2xlarge", center: true })))))));
};
exports.ViewPrescriptionPage = ViewPrescriptionPage;
