"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactUsPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var notifications_1 = require("../../../api/calls/notification/notifications");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var ContactUsPage = function () {
    var canvasRef = (0, react_1.useRef)(null);
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(""), fullNames = _a[0], setFullNames = _a[1];
    var _b = (0, react_1.useState)(""), fullNamesErrorText = _b[0], setFullNamesErrorText = _b[1];
    var _c = (0, react_1.useState)(""), emailAddress = _c[0], setEmailAddress = _c[1];
    var _d = (0, react_1.useState)(""), emailAddressErrorText = _d[0], setEmailAddressErrorText = _d[1];
    var _e = (0, react_1.useState)(""), description = _e[0], setDescription = _e[1];
    var _f = (0, react_1.useState)(""), descriptionErrorText = _f[0], setDescriptionErrorText = _f[1];
    var _g = (0, react_1.useState)(false), isButtonLoading = _g[0], setIsButtonLoading = _g[1];
    var quickContacts = (0, react_1.useState)([
        {
            icon: pro_solid_svg_icons_1.faEnvelopeBadge,
            title: "Email",
            value: "care@thelocalchoice.net",
        },
        {
            icon: pro_solid_svg_icons_1.faPhone,
            title: "Customer Care",
            value: "013 665 1698",
            subText: "Mondays to Fridays: 08:00 to 17:00",
        },
        {
            icon: pro_solid_svg_icons_1.faMapLocation,
            title: "Head Office",
            value: "Cnr. 4th Street and, 2nd Ave, Delmas, 2210, Mpumalanga, South Africa",
        },
        {
            icon: pro_solid_svg_icons_1.faPersonCarryBox,
            title: "Postal Office",
            value: "PO Box 186, Delmas, 2210",
        },
    ])[0];
    var moreOpportunities = (0, react_1.useState)([
        {
            title: "Marketing opportunities:",
            description: "pr@thelocalchoice.net",
        },
        {
            title: "Career opportunities:",
            description: "hr@thelocalchoice.net",
        },
        {
            title: "Franchise opportunities:",
            description: "franchise@thelocalchoice.net",
        },
    ])[0];
    (0, react_1.useEffect)(function () {
        var canvas = canvasRef.current;
        if (canvas) {
            var context = canvas.getContext("2d");
            context.fillStyle = "#000000";
            context.filter = "blur(0px)";
            context.shadowColor = "#ff0000";
            context.shadowBlur = 0;
            context.fillRect(0, 0, context.canvas.width, 10);
        }
    }, []);
    var validateForm = function () {
        var errorCount = 0;
        setFullNamesErrorText("");
        setDescriptionErrorText("");
        if (fullNames.length == 0) {
            setFullNamesErrorText("Name is required!");
            errorCount++;
        }
        if (emailAddress.length == 0) {
            setEmailAddressErrorText("Email Address is required!");
            errorCount++;
        }
        if (description.length == 0) {
            setDescriptionErrorText("Description is required!");
            errorCount++;
        }
        if (errorCount > 0) {
            return false;
        }
        return true;
    };
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement("div", { className: "relative" },
            react_1.default.createElement("div", { className: "flex flex-row justify-center" },
                react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
                    react_1.default.createElement("div", { className: "flex flex-col gap-12" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "We are always happy to support you!", size: "2xlarge", textColor: "text-black", center: true, bold: true }),
                        react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                        react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4" },
                            react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
                                react_1.default.createElement("div", { className: "text-2xl text-primary drop-shadow-lg" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faEnvelopeBadge })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Email", size: "xlarge", textColor: "text-black", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "care@thelocalchoice.net", size: "medium", textColor: "text-primary", onClick: function () {
                                        window.open("mailto:care@thelocalchoice.net?subject=Website Contact Form", "_blank");
                                    } }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "", size: "small", textColor: "text-black", center: true })),
                            react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
                                react_1.default.createElement("div", { className: "text-2xl text-primary drop-shadow-lg" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPhone })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Customer Care", size: "xlarge", textColor: "text-black", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "013 665 1698", size: "medium", textColor: "text-primary", onClick: function () {
                                        window.open("tel:+27136651698", "_blank");
                                    } }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Mondays to Fridays: 08:00 to 17:00", size: "small", textColor: "text-black", center: true })),
                            react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
                                react_1.default.createElement("div", { className: "text-2xl text-primary drop-shadow-lg" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faMapLocation })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Head Office", size: "xlarge", textColor: "text-black", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Cnr. 4th Street and, 2nd Ave, Delmas, 2210, Mpumalanga, South Africa", size: "medium", className: "text-center", textColor: "text-primary", onClick: function () {
                                        window.open("https://www.google.com/maps/place/The+Local+Choice+Pharmacies+Head+Office/@-26.1502129,28.676548,17z/data=!3m1!4b1!4m6!3m5!1s0x1eead2a9ea65dec1:0xd4cc2d50fb6f2469!8m2!3d-26.1502178!4d28.6814189!16s%2Fg%2F11c7wbrhy0?entry=ttu", "_blank");
                                    } }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "", size: "small", textColor: "text-black", center: true })),
                            react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
                                react_1.default.createElement("div", { className: "text-2xl text-primary drop-shadow-lg" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPersonCarryBox })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Postal Office", size: "xlarge", textColor: "text-black", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "PO Box 186, Delmas, 2210", size: "medium", textColor: "text-primary", center: true }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "", size: "small", textColor: "text-black", center: true }))),
                        react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                        react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "bg-white shadow-lg", variant: "filled", addPadding: false },
                            react_1.default.createElement("div", { className: "p-8" },
                                react_1.default.createElement("div", { className: "grid gap-4\n                      grid-cols-1 lg:grid-cols-2" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Full Names", borderColor: "border-borders", value: fullNames, errorText: fullNamesErrorText, onChange: function (v) {
                                            setFullNames(v);
                                        } }),
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email Address", borderColor: "border-borders", value: emailAddress, errorText: emailAddressErrorText, onChange: function (v) {
                                            setEmailAddress(v);
                                        } }),
                                    react_1.default.createElement("div", { className: "lg:col-span-2" },
                                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Text your message here...", lines: 8, borderColor: "border-borders", value: description, errorText: descriptionErrorText, onChange: function (v) {
                                                setDescription(v);
                                            } }))),
                                react_1.default.createElement("div", { className: "flex flex-row justify-start mt-8" },
                                    react_1.default.createElement("div", { className: "w-full md:w-44" },
                                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Submit", backgroundColor: "bg-primary", loading: isButtonLoading, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            if (!validateForm()) return [3 /*break*/, 2];
                                                            setIsButtonLoading(true);
                                                            return [4 /*yield*/, (0, notifications_1.send_email_notification)({
                                                                    name: fullNames,
                                                                    email: emailAddress,
                                                                    message: description,
                                                                    subject: "Website Contact Form",
                                                                    admin_name: "Customer Care",
                                                                }).then(function (data) {
                                                                    if (data) {
                                                                        setIsButtonLoading(false);
                                                                        window.scrollTo({
                                                                            top: 0,
                                                                            behavior: "smooth",
                                                                        });
                                                                        navigate("/contact-us/thank-you");
                                                                    }
                                                                })];
                                                        case 1:
                                                            _a.sent();
                                                            _a.label = 2;
                                                        case 2: return [2 /*return*/];
                                                    }
                                                });
                                            }); } }))))),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Other queries.", size: "2xlarge", textColor: "text-black", center: true, bold: true }),
                        react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-3 gap-8" }, moreOpportunities.map(function (display, index) {
                            return (react_1.default.createElement("div", { key: "more-opportunities-".concat(index), className: "flex flex-col items-center gap-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: display.title, size: "medium", textColor: "text-black" }),
                                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: display.description, size: "medium", textColor: "text-black", decorationColor: "decoration-primary", onClick: function (v) {
                                        window.open("mailto:".concat(display.description, "?subject=").concat(display.title), "_blank");
                                    } })));
                        }))))))));
};
exports.ContactUsPage = ContactUsPage;
