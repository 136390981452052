"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemesPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var categories_1 = require("../../api/calls/product/categories");
var react_router_dom_1 = require("react-router-dom");
var ThemesPage = function (props) {
    var _a = props;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_1.useState)([]), categories = _b[0], setCategories = _b[1];
    var _c = (0, react_1.useState)(true), isLoading = _c[0], setIsloading = _c[1];
    (0, react_1.useEffect)(function () {
        (0, categories_1.getCategories)().then(function (response) {
            if (response.success) {
                var ls = response.content;
                var shopCategory = ls.find(function (c) { return c.name.toLowerCase() == "theme"; });
                if (shopCategory) {
                    (0, categories_1.getCategories)({
                        // page: "1",
                        parent_id: shopCategory.id,
                    }).then(function (response) {
                        if (response.success) {
                            setIsloading(false);
                            var newCategoryList = [];
                            for (var _i = 0, _a = response.content; _i < _a.length; _i++) {
                                var category = _a[_i];
                                newCategoryList.push({
                                    id: category.id,
                                    title: category.name,
                                    image_url: category.image_url,
                                    expanded: false,
                                    items: category.sub_categories.map(function (sub_category, index) { return ({
                                        _id: sub_category.id,
                                        title: sub_category.category_name,
                                        image_url: sub_category.image_url,
                                        items: [],
                                    }); }),
                                });
                            }
                            setCategories(newCategoryList);
                        }
                        else {
                            //dispatch(setMessageModal({ title: "Failed to retrieve categories", messages: [response.message] }));
                        }
                    });
                }
            }
            else {
                // dispatch(setMessageModal({ title: "Failed to retrieve root level categories", messages: [response.message] }));
            }
        });
    }, []);
    return (react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null, isLoading ? (react_1.default.createElement(doshx_controls_web_1.ShimmerBoxControl, null)) : (react_1.default.createElement("div", { className: "flex flex-wrap justify-center gap-4" }, categories.map(function (category, index) { return (react_1.default.createElement("div", { key: index, className: "rounded-md shadow-md cursor-pointer", onClick: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            navigate("/shop/themes/theme/1/".concat(category.id));
        } },
        react_1.default.createElement("div", { className: "relative h-[400px] md:h-60" },
            react_1.default.createElement("img", { src: category.image_url, alt: category.title, className: "w-full h-full object-cover rounded-md" }),
            react_1.default.createElement("div", { className: "absolute bottom-0 left-0 right-0 bg-white p-2 rounded-b-md" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: category === null || category === void 0 ? void 0 : category.title, textColor: "text-black", size: "large", center: true }))))); })))));
};
exports.ThemesPage = ThemesPage;
