"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServicesComponent = void 0;
var pro_thin_svg_icons_1 = require("@fortawesome/pro-thin-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var ServicesComponent = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () {
        //
    }, []);
    var dealsCarouselItems = [
        {
            content: (react_1.default.createElement("div", { className: "flex flex-col justify-center items-center gap-4  bg-white p-2 cursor-pointer" },
                react_1.default.createElement("span", { className: "text-4xl text-primary " },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_thin_svg_icons_1.faFilePrescription })),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Upload Prescription", textColor: "text-black", size: "large", center: true }))),
            onClick: function () {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                navigate("/prescription");
            },
        },
        {
            content: (react_1.default.createElement("div", { className: "flex flex-col justify-center items-center gap-4  bg-white p-2 cursor-pointer" },
                react_1.default.createElement("span", { className: "text-4xl text-primary " },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_thin_svg_icons_1.faCartShopping })),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Shop", textColor: "text-black", size: "medium", center: true }))),
            onClick: function () {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                navigate("/shop/categories");
            },
        },
        {
            content: (react_1.default.createElement("div", { className: "flex flex-col justify-center items-center gap-4  bg-white p-2 cursor-pointer" },
                react_1.default.createElement("span", { className: "text-4xl text-primary " },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_thin_svg_icons_1.faLocationDot })),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Find A Store", textColor: "text-black", size: "medium", center: true }))),
            onClick: function () {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                navigate("/find-store");
            },
        },
        {
            content: (react_1.default.createElement("div", { className: "flex flex-col justify-center items-center gap-4  bg-white p-2 cursor-pointer" },
                react_1.default.createElement("span", { className: "text-4xl text-primary " },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_thin_svg_icons_1.faSquarePlus })),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Virtual Consultation", textColor: "text-black", size: "medium", center: true }))),
            onClick: function () {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                navigate("/virtual-consultation");
            },
        },
    ];
    return (react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-4 gap-4 bg-primary rounded-xl p-4" },
        react_1.default.createElement("div", { className: "flex justify-center items-center md:col-span-1" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Services", textColor: "text-white", size: "large", bold: true })),
        react_1.default.createElement("div", { className: "flex justify-center items-center md:col-span-3 gap-4" },
            react_1.default.createElement(doshx_controls_web_1.ContentCarouselControl, { shortSwipes: true, heightClasses: "h-96 md:h-60", breakpoints: {
                    sm: {
                        viewport: 1,
                    },
                    md: {
                        viewport: 4,
                    },
                }, centerSlides: false, slideTimeout: 2500, colors: {
                    bulletColor: "bg-white",
                }, items: dealsCarouselItems }))));
};
exports.ServicesComponent = ServicesComponent;
