"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.radio_colors = void 0;
exports.radio_colors = {
    error: {
        textColor: "text-red-500",
    },
    radio: {
        iconColor: "text-primary",
    },
};
