"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChoiceCardPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var choice_card_1 = require("../../components/choice_card");
var react_router_dom_1 = require("react-router-dom");
var main_1 = require("../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var get_member_1 = require("../../api/calls/loyalty/get_member");
var faqs_1 = require("../../api/calls/strapi/faqs");
var react_markdown_1 = require("react-markdown/lib/react-markdown");
var ChoiceCardPage = function () {
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_1.useState)(null), loyaltyMember = _a[0], setLoyaltyMember = _a[1];
    var _b = (0, react_1.useState)(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = (0, react_1.useState)([]), faqsData = _c[0], setFaqsData = _c[1];
    var _d = (0, react_1.useState)([]), accountFaqs = _d[0], setAccountFaqs = _d[1];
    var _e = (0, react_1.useState)([]), generalFaqs = _e[0], setGeneralFaqs = _e[1];
    var _f = (0, react_1.useState)([]), programFaqs = _f[0], setProgramFaqs = _f[1];
    (0, react_1.useEffect)(function () {
        (0, faqs_1.faqs)().then(function (response) {
            if (response) {
                setFaqsData(response);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                //   dispatch(setMessageModal({ title: "Something went wrong!", messages: [response.message] }));
            }
        });
    }, []);
    var LinkRenderer = function (_a) {
        var children = _a.children, href = _a.href;
        if (!href) {
            return react_1.default.createElement(react_1.default.Fragment, null, children);
        }
        return (react_1.default.createElement("a", { href: href, style: { color: "#FC4F06", textDecoration: "underline" } }, children));
    };
    (0, react_1.useEffect)(function () {
        var accountFaqs_ = [];
        var generalFaqs_ = [];
        var programFaqs_ = [];
        if (faqsData) {
            for (var _i = 0, faqsData_1 = faqsData; _i < faqsData_1.length; _i++) {
                var faq = faqsData_1[_i];
                switch (faq === null || faq === void 0 ? void 0 : faq.type) {
                    case "account":
                        accountFaqs_.push({
                            expanded: false,
                            header: faq === null || faq === void 0 ? void 0 : faq.question,
                            content: react_1.default.createElement(react_markdown_1.ReactMarkdown, { components: { a: LinkRenderer } }, faq === null || faq === void 0 ? void 0 : faq.answer),
                        });
                        break;
                    case "general":
                        generalFaqs_.push({
                            expanded: false,
                            header: faq === null || faq === void 0 ? void 0 : faq.question,
                            content: react_1.default.createElement(react_markdown_1.ReactMarkdown, { components: { a: LinkRenderer } }, faq === null || faq === void 0 ? void 0 : faq.answer),
                        });
                        break;
                    case "program":
                        programFaqs_.push({
                            expanded: false,
                            header: faq === null || faq === void 0 ? void 0 : faq.question,
                            content: react_1.default.createElement(react_markdown_1.ReactMarkdown, { components: { a: LinkRenderer } }, faq === null || faq === void 0 ? void 0 : faq.answer),
                        });
                        break;
                }
            }
            setAccountFaqs(accountFaqs_);
            setGeneralFaqs(generalFaqs_);
            setProgramFaqs(programFaqs_);
        }
    }, [faqsData]);
    (0, react_1.useEffect)(function () {
        (0, get_member_1.loyalty_member)({
            cell_number: user.cell_number,
        }).then(function (response) {
            if (response.success) {
                setLoyaltyMember(response.content);
            }
            else {
                //   dispatch(setMessageModal({ title: "Something went wrong!", messages: [response.message] }));
            }
        });
    }, [user]);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
            react_1.default.createElement(choice_card_1.ChoiceCardComponent, { buttonText: "Get Card", onButtonClick: function () {
                    var _a;
                    if (user === null || user === void 0 ? void 0 : user._id) {
                        if ((_a = loyaltyMember === null || loyaltyMember === void 0 ? void 0 : loyaltyMember.card) === null || _a === void 0 ? void 0 : _a.number) {
                            dispatch((0, main_1.setMessageModal)({
                                title: "Choice Card",
                                messages: ["Good news! It looks like you have an existing choice card linked to this account"],
                            }));
                        }
                        else {
                            navigate("/choice-card-application");
                        }
                    }
                    else {
                        navigate("/choice-card-application");
                        // dispatch(setDrawerPage("login"));
                        //dispatch(setDrawerOpen(true));
                    }
                } }),
            react_1.default.createElement("div", { className: "flex flex-col pt-28" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "HERE'S A FEW...", textColor: "text-success", size: "medium" }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Frequently asked questions.", textColor: "text-black", size: "3xlarge", bold: true })),
            isLoading ? (react_1.default.createElement(doshx_controls_web_1.ShimmerBoxControl, null)) : (react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "flex flex-col pt-10" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "GENERAL QUESTIONS", textColor: "text-success", size: "medium" })),
                react_1.default.createElement("div", { className: "pt-4" },
                    react_1.default.createElement(doshx_controls_web_1.AccordionControl, { colors: {
                            titleTextColor: "text-black",
                            titleBackgroundColor: "bg-gray-100",
                            contentTextColor: "text-black",
                            contentBackgroundColor: "bg-white",
                            borderColor: "border-transparent",
                            divideColor: "divide-gray-200",
                        }, items: generalFaqs })),
                react_1.default.createElement("div", { className: "flex flex-col pt-10" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "YOUR ACCOUNT", textColor: "text-success", size: "medium" })),
                react_1.default.createElement("div", { className: "pt-4" },
                    react_1.default.createElement(doshx_controls_web_1.AccordionControl, { colors: {
                            titleTextColor: "text-black",
                            titleBackgroundColor: "bg-gray-100",
                            contentTextColor: "text-black",
                            contentBackgroundColor: "bg-white",
                            borderColor: "border-transparent",
                            divideColor: "divide-gray-200",
                        }, items: accountFaqs })),
                react_1.default.createElement("div", { className: "flex flex-col pt-10" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "HOW THE PROGRAM WORKS", textColor: "text-success", size: "medium" })),
                react_1.default.createElement("div", { className: "pt-4" },
                    react_1.default.createElement(doshx_controls_web_1.AccordionControl, { colors: {
                            titleTextColor: "text-black",
                            titleBackgroundColor: "bg-gray-100",
                            contentTextColor: "text-black",
                            contentBackgroundColor: "bg-white",
                            borderColor: "border-transparent",
                            divideColor: "divide-gray-200",
                        }, items: programFaqs })))))));
};
exports.ChoiceCardPage = ChoiceCardPage;
