"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var blogs_1 = require("../../../api/calls/strapi/blogs");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var react_router_dom_1 = require("react-router-dom");
var news_1 = require("../../../reducers/slices/news");
var NewsPage = function (props) {
    var _a = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var news = (0, react_redux_1.useSelector)(function (state) { return state.newsState; }).news;
    var _b = (0, react_1.useState)(false), loading = _b[0], setLoading = _b[1];
    (0, react_1.useEffect)(function () {
        setLoading(true);
        (0, blogs_1.get_blogs)().then(function (data) {
            setLoading(false);
            if (data && data.length > 0) {
                // setBlogs(data);
                dispatch((0, news_1.setNewsList)(data));
            }
            else {
                dispatch((0, main_1.setMessageModal)({
                    title: "Oops!",
                    messages: ["Sorry! We could not retrieve the news articles at the moment! Please try again later."],
                }));
            }
        });
    }, []);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null, loading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "large" })) : (react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" }, news.list.map(function (blog, index) {
            return (react_1.default.createElement("div", { key: index, className: "p-4 bg-gray-100 rounded-lg shadow-lg flex flex-col gap-4 justify-between hover:scale-105 transition cursor-pointer", onClick: function () {
                    navigate("/news/".concat(blog.id));
                } },
                react_1.default.createElement("div", { className: "w-full" },
                    react_1.default.createElement("div", { className: "text-xl font-bold" }, blog.title),
                    react_1.default.createElement("div", { className: "text-sm text-gray-500" }, blog.description)),
                react_1.default.createElement("div", { className: "aspect-square rounded-lg overflow-hidden" },
                    react_1.default.createElement("img", { src: "".concat(process.env.STRAPI_TLC_URL).concat(blog.image.url), alt: "blog", className: "w-full h-full object-cover" }))));
        }))))));
};
exports.NewsPage = NewsPage;
