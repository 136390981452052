"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnersComponent = void 0;
var react_1 = __importStar(require("react"));
var PartnersComponent = function () {
    (0, react_1.useEffect)(function () { }, []);
    return (
    //To Do - This will returned, until a full list of parners is provided
    react_1.default.createElement("div", { className: "" })
    // <PartnerControl
    //   colors={{
    //     buttons: {
    //       iconColor: `text-primary`,
    //       borderColor: `border-transparent`,
    //     },
    //   }}
    //   slidesPerView={7}
    //   spaceBetween={100}
    //   partners={[
    //     { logoUrl: "https://thelocalchoice.co.za/wp-content/uploads/2017/03/TLC-Full-colour-logo.png" },
    //     { logoUrl: "https://thelocalchoice.co.za/wp-content/uploads/2017/03/TLC-Full-colour-logo.png" },
    //     { logoUrl: "https://thelocalchoice.co.za/wp-content/uploads/2017/03/TLC-Full-colour-logo.png" },
    //     { logoUrl: "https://thelocalchoice.co.za/wp-content/uploads/2017/03/TLC-Full-colour-logo.png" }, //
    //     { logoUrl: "https://thelocalchoice.co.za/wp-content/uploads/2017/03/TLC-Full-colour-logo.png" },
    //     { logoUrl: "https://thelocalchoice.co.za/wp-content/uploads/2017/03/TLC-Full-colour-logo.png" },
    //     { logoUrl: "https://thelocalchoice.co.za/wp-content/uploads/2017/03/TLC-Full-colour-logo.png" },
    //   ]}
    // />
    );
};
exports.PartnersComponent = PartnersComponent;
