"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeSliders = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var home_sliders_1 = require("../../../../api/calls/strapi/home_sliders");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var HomeSliders = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_1.useState)([]), sliders = _a[0], setSliders = _a[1];
    var _b = (0, react_1.useState)([]), sliderCarouselItems = _b[0], setSliderCarouselItems = _b[1];
    var _c = (0, react_1.useState)(true), isLoading = _c[0], setIsLoading = _c[1];
    (0, react_1.useEffect)(function () {
        (0, home_sliders_1.home_sliders)().then(function (data) {
            setIsLoading(false);
            if (data && data.length > 0) {
                setSliders(data);
            }
            else {
            }
        });
    }, []);
    (0, react_1.useEffect)(function () {
        var sample = [];
        var _loop_1 = function (slide) {
            sample.push({
                content: (react_1.default.createElement("div", { className: "flex justify-center items-end pb-10 cursor-pointer", onClick: function () {
                        switch (slide === null || slide === void 0 ? void 0 : slide.type) {
                            case "page":
                                navigate("".concat(slide === null || slide === void 0 ? void 0 : slide.identifier));
                                break;
                            case "product":
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                                navigate("/product/".concat(slide === null || slide === void 0 ? void 0 : slide.identifier));
                                break;
                            case "category":
                                window.scrollTo({
                                    top: 450,
                                    behavior: "smooth",
                                });
                                navigate("/shop/categories/category/1/".concat(slide === null || slide === void 0 ? void 0 : slide.identifier));
                                break;
                        }
                    } },
                    react_1.default.createElement("img", { className: "w-full h-full  absolute top-0 left-0", src: "".concat(process.env.STRAPI_TLC_URL).concat(slide.image[0].url) }))),
            });
        };
        for (var _i = 0, sliders_1 = sliders; _i < sliders_1.length; _i++) {
            var slide = sliders_1[_i];
            _loop_1(slide);
        }
        setSliderCarouselItems(sample);
    }, [sliders]);
    return isLoading ? (react_1.default.createElement("div", { className: "flex flex-col  items-center justify-center h-full" },
        react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge", progressColor: "text-primary" }))) : (react_1.default.createElement(doshx_controls_web_1.ContentCarouselControl, { shortSwipes: true, heightClasses: "h-[300px] md:h-[450px] ", colors: {
            bulletColor: "bg-primary",
        }, slideTimeout: 3700, viewport: 1, items: sliderCarouselItems }));
};
exports.HomeSliders = HomeSliders;
