"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var basket_1 = require("../../../reducers/slices/basket/basket");
var checkout_1 = require("../../../reducers/slices/checkout");
var get_card_vault_1 = require("../../../api/calls/payment-engine/paygate/get_card_vault");
var main_1 = require("../../../reducers/slices/main");
var partners_1 = require("../../components/partners");
var choice_card_1 = require("../../components/choice_card");
var google_tags_1 = require("../../../constants/google_tags");
var CheckoutPage = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var basket = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }).basket;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _k = (0, react_redux_1.useSelector)(function (state) { return state.checkoutState; }), checkoutSteps = _k.checkoutSteps, currentCheckoutStep = _k.currentCheckoutStep, deliveryOrCollection = _k.deliveryOrCollection, selectedBranch = _k.selectedBranch, selectedAddress = _k.selectedAddress;
    (0, react_1.useEffect)(function () {
        var _a;
        if (!basket) {
            navigate("/shop/categories");
        }
        else {
            dispatch((0, basket_1.setDeliveryMethod)(null));
            (0, get_card_vault_1.get_card_vault)({
                integration_id: (_a = user._id) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                var _a;
                if (response.success) {
                    dispatch((0, checkout_1.setBankCards)(response.content.result));
                    dispatch((0, checkout_1.setHasBankCards)(((_a = response.content.result) === null || _a === void 0 ? void 0 : _a.length) > 0));
                    // dispatch(refreshSteps());
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
    }, []);
    (0, react_1.useEffect)(function () {
        if (basket) {
            (0, google_tags_1.trackBeginCheckout)({
                price: basket === null || basket === void 0 ? void 0 : basket.outstanding_amount,
                products: basket === null || basket === void 0 ? void 0 : basket.product_list,
                _id: basket._id,
            });
        }
    }, [basket]);
    var _l = (0, react_1.useState)([]), discountDeals = _l[0], setDiscoutDeals = _l[1];
    // useEffect(() => {
    //   get_deals_for_basket({
    //     basket_id: basket?._id,
    //   }).then((response) => {
    //     if (response.success) {
    //       setDiscoutDeals(response.content ?? []);
    //     } else {
    //       dispatch(
    //         setMessageModal({
    //           title: "Something went wrong!",
    //           messages: [response.message],
    //         }),
    //       );
    //     }
    //   });
    // }, [basket]);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col lg:flex-row lg:items-start gap-4" },
            react_1.default.createElement("div", { className: "flex-grow" },
                react_1.default.createElement(doshx_controls_web_1.StepperControl, { stepPillBackgroundColor: "bg-primary", stepPillCompletedBackgroundColor: "bg-success", lineColor: "border-primary", currentStep: currentCheckoutStep, 
                    // onNextClick={(step) => {
                    // }}
                    // onPreviousClick={(step) => {
                    // }}
                    // steps={customSteps}
                    steps: checkoutSteps })),
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex-none flex flex-col", borderColor: "border-borders" },
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Order Summary", textColor: "text-black", size: "large", bold: true }),
                    selectedAddress && (react_1.default.createElement("div", null,
                        react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                        react_1.default.createElement("div", { className: "flex flex-row items-center gap-2" },
                            react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Delivery address:", size: "small", textColor: "text-black", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedAddress.full_address, size: "small", textColor: "text-black" }))))),
                    selectedBranch && (react_1.default.createElement("div", null,
                        react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                        react_1.default.createElement("div", { className: "flex flex-row items-center gap-2" },
                            react_1.default.createElement("div", { className: "flex-1" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedBranch.branch.display_name, size: "small", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "py-1 px-2 bg-primary rounded-lg flex flex-row items-center" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: deliveryOrCollection == "delivery" ? "".concat(selectedBranch.distance, " km") : "Collection", size: "xsmall", textColor: "text-white" }))))),
                    react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                    react_1.default.createElement("div", null, basket &&
                        basket.product_list.map(function (product, index) {
                            return (react_1.default.createElement("div", { key: index, className: "flex flex-row justify-between items-center" },
                                react_1.default.createElement("div", { className: "pr-4 pb-[2px]" },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: product.title, textColor: "text-black" })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                        value: (parseFloat(product.price) * parseFloat(product.quantity)).toFixed(2),
                                    }), textColor: "text-black" })));
                        }))),
                react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Delivery Fee", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                value: (_b = (_a = basket === null || basket === void 0 ? void 0 : basket.delivery_fee) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "0",
                                decimalPlaces: 2,
                            }), textColor: "text-black" }))),
                react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Loyalty Discount", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                value: (_d = (_c = basket === null || basket === void 0 ? void 0 : basket.product_list) === null || _c === void 0 ? void 0 : _c.reduce(function (a, b) { var _a, _b; return a + parseFloat((_b = (_a = b === null || b === void 0 ? void 0 : b.deal) === null || _a === void 0 ? void 0 : _a.discount) !== null && _b !== void 0 ? _b : 0); }, 0)) !== null && _d !== void 0 ? _d : "0",
                                decimalPlaces: 2,
                            }), size: "large", textColor: "text-black" })),
                    react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Basket Total", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                value: (_f = (_e = basket === null || basket === void 0 ? void 0 : basket.total) === null || _e === void 0 ? void 0 : _e.toString()) !== null && _f !== void 0 ? _f : "0",
                                decimalPlaces: 2,
                            }), textColor: "text-black" }))),
                react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Outstanding Amount", textColor: "text-black", size: "large", bold: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                            value: (_h = (_g = basket === null || basket === void 0 ? void 0 : basket.outstanding_amount) === null || _g === void 0 ? void 0 : _g.toString()) !== null && _h !== void 0 ? _h : (_j = basket === null || basket === void 0 ? void 0 : basket.total) === null || _j === void 0 ? void 0 : _j.toString(),
                            decimalPlaces: 2,
                        }), textColor: "text-black", size: "large", bold: true })))),
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "" },
            react_1.default.createElement(partners_1.PartnersComponent, null)),
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(choice_card_1.ChoiceCardComponent, null))));
};
exports.CheckoutPage = CheckoutPage;
