"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatedAddressObject = exports.getAddressComponentByType = void 0;
function getAddressComponentByType(components, type) {
    for (var _i = 0, components_1 = components; _i < components_1.length; _i++) {
        var component = components_1[_i];
        if (component.types.includes(type)) {
            return component.long_name;
        }
    }
    return null;
}
exports.getAddressComponentByType = getAddressComponentByType;
function formatedAddressObject(addresObject) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return {
        full_address: addresObject.formatted_address,
        longitude: addresObject.geometry.location.lng.toString(),
        latitude: addresObject.geometry.location.lat.toString(),
        line_one: (_c = "".concat((_a = getAddressComponentByType(addresObject.address_components, "street_number")) !== null && _a !== void 0 ? _a : "", " ").concat((_b = getAddressComponentByType(addresObject.address_components, "route")) !== null && _b !== void 0 ? _b : "").trim()) !== null && _c !== void 0 ? _c : "",
        line_two: (_d = getAddressComponentByType(addresObject.address_components, "sublocality_level_1")) !== null && _d !== void 0 ? _d : "",
        town: (_e = getAddressComponentByType(addresObject.address_components, "locality")) !== null && _e !== void 0 ? _e : "",
        city: (_f = getAddressComponentByType(addresObject.address_components, "administrative_area_level_2")) !== null && _f !== void 0 ? _f : "",
        province: (_g = getAddressComponentByType(addresObject.address_components, "administrative_area_level_1")) !== null && _g !== void 0 ? _g : "",
        country: (_h = getAddressComponentByType(addresObject.address_components, "country")) !== null && _h !== void 0 ? _h : "",
        postal_code: (_j = getAddressComponentByType(addresObject.address_components, "postal_code")) !== null && _j !== void 0 ? _j : "",
    };
}
exports.formatedAddressObject = formatedAddressObject;
