"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactUsThankYouPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_redux_1 = require("react-redux");
var ContactUsThankYouPage = function () {
    var canvasRef = (0, react_1.useRef)(null);
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(""), fullNames = _a[0], setFullNames = _a[1];
    var _b = (0, react_1.useState)(""), fullNamesErrorText = _b[0], setFullNamesErrorText = _b[1];
    var _c = (0, react_1.useState)(""), emailAddress = _c[0], setEmailAddress = _c[1];
    var _d = (0, react_1.useState)(""), emailAddressErrorText = _d[0], setEmailAddressErrorText = _d[1];
    var _e = (0, react_1.useState)(""), description = _e[0], setDescription = _e[1];
    var _f = (0, react_1.useState)(""), descriptionErrorText = _f[0], setDescriptionErrorText = _f[1];
    var _g = (0, react_1.useState)(false), isButtonLoading = _g[0], setIsButtonLoading = _g[1];
    (0, react_1.useEffect)(function () {
        var canvas = canvasRef.current;
        if (canvas) {
            var context = canvas.getContext("2d");
            context.fillStyle = "#000000";
            context.filter = "blur(0px)";
            context.shadowColor = "#ff0000";
            context.shadowBlur = 0;
            context.fillRect(0, 0, context.canvas.width, 10);
        }
    }, []);
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement("div", { className: "relative" },
            react_1.default.createElement("div", { className: "flex flex-row justify-center" },
                react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, null,
                    react_1.default.createElement("div", { className: "flex flex-col gap-12" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Thank You!", size: "5xlarge", textColor: "text-success", center: true, bold: true }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: " Thank you for reaching out! Your message has been successfully sent. We will get back to you as soon as possible. Have a great day!", size: "medium", textColor: "text-black", center: true }),
                        react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                        react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4" },
                            react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
                                react_1.default.createElement("div", { className: "text-2xl text-primary drop-shadow-lg" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faEnvelopeBadge })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Email", size: "xlarge", textColor: "text-black", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "care@thelocalchoice.net", size: "medium", textColor: "text-primary", onClick: function () {
                                        window.open("mailto:care@thelocalchoice.net?subject=Website Contact Form", "_blank");
                                    } }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "", size: "small", textColor: "text-black", center: true })),
                            react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
                                react_1.default.createElement("div", { className: "text-2xl text-primary drop-shadow-lg" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPhone })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Customer Care", size: "xlarge", textColor: "text-black", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "013 665 1698", size: "medium", textColor: "text-primary", onClick: function () {
                                        window.open("tel:+27136651698", "_blank");
                                    } }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Mondays to Fridays: 08:00 to 17:00", size: "small", textColor: "text-black", center: true })),
                            react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
                                react_1.default.createElement("div", { className: "text-2xl text-primary drop-shadow-lg" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faMapLocation })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Head Office", size: "xlarge", textColor: "text-black", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Cnr. 4th Street and, 2nd Ave, Delmas, 2210, Mpumalanga, South Africa", size: "medium", className: "text-center", textColor: "text-primary", onClick: function () {
                                        window.open("https://www.google.com/maps/place/The+Local+Choice+Pharmacies+Head+Office/@-26.1502129,28.676548,17z/data=!3m1!4b1!4m6!3m5!1s0x1eead2a9ea65dec1:0xd4cc2d50fb6f2469!8m2!3d-26.1502178!4d28.6814189!16s%2Fg%2F11c7wbrhy0?entry=ttu", "_blank");
                                    } }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "", size: "small", textColor: "text-black", center: true })),
                            react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
                                react_1.default.createElement("div", { className: "text-2xl text-primary drop-shadow-lg" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPersonCarryBox })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Postal Office", size: "xlarge", textColor: "text-black", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "PO Box 186, Delmas, 2210", size: "medium", textColor: "text-primary", center: true }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "", size: "small", textColor: "text-black", center: true })))))))));
};
exports.ContactUsThankYouPage = ContactUsThankYouPage;
