"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeBanner = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var home_banners_1 = require("../../../../api/calls/strapi/home_banners");
var react_router_dom_1 = require("react-router-dom");
var HomeBanner = function () {
    var _a;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_1.useState)(null), bannerDetails = _b[0], setBannerDetails = _b[1];
    var _c = (0, react_1.useState)(true), isLoading = _c[0], setIsLoading = _c[1];
    (0, react_1.useEffect)(function () {
        (0, home_banners_1.home_banners)().then(function (data) {
            setIsLoading(false);
            if (data && data.length > 0) {
                setBannerDetails(data[0]);
            }
            else {
            }
        });
    }, []);
    return isLoading ? (react_1.default.createElement("div", { className: "flex flex-col  items-center justify-center h-full" },
        react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge", progressColor: "text-primary" }))) : (react_1.default.createElement("div", { className: "flex flex-col relative items-center px-0 shadow-md  rounded-lg  h-[500px] md:h-[420px] cursor-pointer", onClick: function () {
            switch (bannerDetails === null || bannerDetails === void 0 ? void 0 : bannerDetails.type) {
                case "page":
                    navigate("".concat(bannerDetails === null || bannerDetails === void 0 ? void 0 : bannerDetails.identifier));
                    break;
                case "product":
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    navigate("/product/".concat(bannerDetails === null || bannerDetails === void 0 ? void 0 : bannerDetails.identifier));
                    break;
                case "category":
                    window.scrollTo({
                        top: 450,
                        behavior: "smooth",
                    });
                    navigate("/shop/category/category/1/".concat(bannerDetails === null || bannerDetails === void 0 ? void 0 : bannerDetails.identifier));
                    break;
            }
        } },
        react_1.default.createElement("img", { className: " w-full object-cover absolute rounded-lg -z-20", src: "".concat(process.env.STRAPI_TLC_URL).concat((_a = bannerDetails === null || bannerDetails === void 0 ? void 0 : bannerDetails.image) === null || _a === void 0 ? void 0 : _a.url) }),
        react_1.default.createElement("div", { className: "p-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: bannerDetails === null || bannerDetails === void 0 ? void 0 : bannerDetails.title, textColor: "text-black", size: "2xlarge", bold: true })),
        react_1.default.createElement("div", { className: "flex flex-col justify-center items-center h-full pb-10 px-2" },
            react_1.default.createElement("div", { className: "flex flex-col justify-end gap-2 p-2 " },
                react_1.default.createElement("div", { className: "flex justify-center cursor-pointer" })))));
};
exports.HomeBanner = HomeBanner;
