"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectToQueryString = void 0;
function objectToQueryString(obj, prependQuestion) {
    if (prependQuestion === void 0) { prependQuestion = true; }
    return obj
        ? "".concat(prependQuestion ? "?" : "").concat(Object.keys(obj)
            .map(function (key) {
            return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
        })
            .join("&"))
        : "";
}
exports.objectToQueryString = objectToQueryString;
